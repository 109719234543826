import {keyframes} from '@emotion/core';
import styled from '@emotion/styled';
import { mq } from "../../base";

export const BoxKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const FormContainer = styled.div`
  
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto 0px;
  box-sizing: border-box;
  width: 100%;
  height: 665px;
  max-width: 495px;
  min-height: 665px !important;
  overflow: hidden;

  background-color: rgba(256, 256, 256, 0);
  border-radius: 12px;
  
  ${mq.laptop} {
    max-width: 990px;
    margin: auto;
    width: 990px;
    box-shadow: 0px 29px 50px rgba(13, 113, 200, 0.15);
  }
`;


export const Box = styled.div`
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    position: relative;
    box-sizing: border-box;

    width: 100%;
    max-width: 495px;
    height: 665px;
    border-radius: 12px 0px 0px 12px;

    color:#525a5f;
    font-family: "Open Sans", "Source Sans Pro", sans-serif;

    background: white;

    /* animation-name: ${BoxKeyframes};
    animation-duration: 0.65s;
    animation-timing-function: ease-in;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running; */
    
    ${mq.laptop} {
      width: 50%;
      width: 495px;
    }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  padding: 0px 24px;
    
  ${mq.laptop} {
    padding: 0px 55px;
  }
`;


export const FormFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
`;
