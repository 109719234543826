import styled from '@emotion/styled';
import { mq } from '../../base';

export const Container = styled.div`
    width: 100%
`;

export const MobileFormat = styled.div`
    .codeSentText {
        font-size: 28px;
        line-height: 36px;
    }

    .formMessageText {
        font-size: 16px;
        line-height: 24px;
    }

    .formShortcutText {
        font-size: 16px;
        line-height: 24px;
    }

    ${mq.mobileL} {
        .codeSentText {
            font-size: 20px;
            line-height: 24px;
        }

        .formMessageText {
            font-size: 14px;
            line-height: 20px;
        }

        .formShortcutText {
            font-size: 14px;
            line-height: 16px;
        }
    }
`;