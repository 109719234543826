import React from 'react';
import PropTypes from 'prop-types';
import * as s from './FormTitle.styles';

function FormTitle({ titleText }) {
    const hasTitle = Boolean(titleText);
    return (
      <s.Title hideTitle={!hasTitle}>{hasTitle ? titleText : 'Sign In'}</s.Title>
    );
};

FormTitle.propTypes = {
    titleText: PropTypes.string
}

export default FormTitle;