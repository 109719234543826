import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as s from './FormInput.styles';

function FormInput({
    className,
    id,
    type,
    name,
    value,
    inputLabel,
    hasError,
    handleChange,
    isStatic
  }) {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (value) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      }, [value])
    
      return (
        <s.InputBox>
          <s.Input
            className={className}
            id={id}
            name={name}
            type={type}
            hasError={hasError}
            value={value}
            onChange={handleChange}
            isStatic={isStatic}
            disabled={isStatic}
          />
          <s.Label className={`portal-branding-signin-email-label ${isActive ? "Active portal-branding-signin-email-active" : ""}`} htmlFor={id} hasError={hasError} isStatic={isStatic}>
            {`${inputLabel}`}   
          </s.Label>
        </s.InputBox>
      );
    }

FormInput.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    inputLabel: PropTypes.string,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    isStatic: PropTypes.bool
}

FormInput.defaultProps = {
    type: "text",
    hasError: false,
    inputLabel: '',
}

export default FormInput;