import { setActiveForm } from "./setActiveForm";
import { checkCookies } from "./checkCookies";
import { setErrorId } from "./setErrorId";
import { fetchData } from "../api/fetchDataAPI";
import { sendAnalytics } from "../api/sendAnalyticsAPI";

import { redirecToSignIn, redirectToSales } from "../helpers/redirects";
import { errorIdMessage } from "../helpers/errors";
import { displayTitle } from "../helpers/urls";
import { validatePortalDataSchema } from '../api/responseValidation';

import * as types from '../constants/actionTypes';
import * as f from '../constants/formTypes';

export const loadPortalData = (pName, pQueryCode, pSsoCode) => async (dispatch) => {

  if (pName) {
    window.portalAccount = pName; // needed for logging. use portalName since there's no account name yet
    
    const {status, data} = await fetchData(pName);
    // const {status, data} = await mockFetchData(pName);

    if ((status >= 200 && status < 300) && data.isPortalEnabled && (data.PortalName !== pName)) {
      // redirect - PL-23373
      redirecToSignIn(data.PortalName);
      displayTitle();

    } else if ((status >= 200 && status < 300) && data.isPortalEnabled) {
      
      validatePortalDataSchema(data);
      dispatch(updatePortalData(data)); // needed for signin page if cookies are expired or don't exist
      dispatch(checkCookies(data.AccountId, data.PortalName, pQueryCode, pSsoCode));
      displayTitle(data.PortalCompanyName || 'Portal Sign In'); 
      sendAnalytics("Portal Login Screen Loaded",data.AccountId,data.Name,data.isPortalEnabled,data.isActive,document.referrer);

    } else if ((status >= 200 && status < 300) && !data.isPortalEnabled) {
      dispatch(setActiveForm(f.OFFLINE));
      displayTitle( data.PortalCompanyName || 'Portal Sign In');
      sendAnalytics("Portal Login Screen Loaded",data.AccountId,data.Name,data.isPortalEnabled,data.isActive,document.referrer);

    } else if (status >= 300 && status < 400) {
      redirecToSignIn(data.PortalName);
      displayTitle();

    } else {
      const errId = errorIdMessage(status, data);
      dispatch(setErrorId(errId));
      dispatch(setActiveForm(f.NO_DATA));
      displayTitle('Portal Sign In');
    } 

  } else {
    redirectToSales();
  }
} 
  
export const updatePortalData = data => ({
    type: types.UPDATE_PORTAL_DATA,
    payload: { data }
});
