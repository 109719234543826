import styled from '@emotion/styled';
import { colors } from '../../base';

export const IconBackground = styled.div`
    height: ${({size}) => (size) ? `${size}px` : `42px`};
    width: ${({size}) => (size) ? `${size}px` : `42px`};
    border-radius: 42px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    margin: 16px ${({centered}) => (centered) ? `auto` : `14px`};
    background-color: ${({isSelected}) => (isSelected) ? colors['blue-light'] : colors['blue-lighter']};
`;

export const BoxIcon = styled.svg`
    color: ${({isSelected}) => (isSelected) ? colors['method'] : colors['blue']};
    height: 18px;
    width: 24px;
    fill: none;
`;