import styled from "@emotion/styled";
import { colors } from "../../base";

const _blue = colors['blue'];
const _white = colors['white'];
const _hoverBlue = colors['blue-dark'];
const _focusedBlue = colors['blue-darker'];

export const Button = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 384px;
  height: 48px;
  padding: 12px;
  margin: auto;
  margin-bottom: 16px;
  position: relative;

  cursor: pointer;
  border: 2px solid ${colors['blue']};
  border-radius: 48px;
  background-color: ${({ buttonStyle }) =>
    buttonStyle === 'primary' ? _blue : _white};
  opacity: ${({ disabled }) => (disabled ? '0.85' : '1')};

  font-size: ${({ buttonStyle }) => (buttonStyle === 'primary' ? '16px' : '17px')};
  font-weight: 600;
  line-height: ${({ buttonStyle }) => (buttonStyle === 'primary' ? '24px' : '20px')};
  text-align: center;
  user-select: none;

  color: ${({ buttonStyle }) => (buttonStyle === 'primary' ? _white : _blue)};

  &:hover,
  &:active,
  &:focus {
    /* opacity: 0.85;  */
    outline: none;
  }

  &:hover {
    background-color: ${({ buttonStyle }) =>
      buttonStyle === 'primary' ? _hoverBlue : _white};
    color: ${({ buttonStyle }) =>
      buttonStyle === 'primary' ? _white : _hoverBlue};
    border-color: ${_hoverBlue};
  }

  &:active {
    background-color: ${({ buttonStyle }) =>
      buttonStyle === 'primary' ? _focusedBlue : _white};
    color: ${({ buttonStyle }) =>
      buttonStyle === 'primary' ? _white : _focusedBlue};
    border-color: ${_focusedBlue};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: ${({ buttonStyle }) =>
        buttonStyle === 'primary' ? _blue : _white};
      color: ${({ buttonStyle }) =>
        buttonStyle === 'primary' ? _white : _blue};
      border-color: ${_blue};
    }
  }
`;

export const ButtonIcon = styled.svg`
    height: ${({ height }) => (height) ? `${height}px` : `10px`};
    width: ${({ width }) => (width) ?  `${width}px` : `10px`};
    fill: none;
    position: absolute;
    left: 14px;
`;
