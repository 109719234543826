import { sendCode } from "../api/sendCodeAPI";
import { setCookie } from "../helpers/cookie";
import { redirectToPortal } from "../helpers/redirects";
import { errorIdMessage } from "../helpers/errors";
import { COOKIE_NAME, COOKIE_NAME_V2 } from '../constants/cookieNames';
import * as types from '../constants/actionTypes';
import { validateTokenSchema } from "../api/responseValidation";

// send the code for validation
// save session cookies if code is valid
export const sendPortalCode = ({AccountId, PortalName, userEmail, portalCode, stayOn, portalSsoCode, handleSubmitError}) => async (dispatch) => {
    // const {status, data} = await mockSendCode();
    const {status, data} =  await sendCode(AccountId, userEmail, portalCode, portalSsoCode);

    if ( status >= 200 && status < 300 ) {
      dispatch(setSessionStatus(stayOn));
      validateTokenSchema(data);
      setCookie(COOKIE_NAME, data.portalSession.sessionToken, stayOn, false, 30);
      setCookie(COOKIE_NAME_V2, data.portalSession.accessTokenV2, stayOn, false, 30);
      redirectToPortal(PortalName, data.accountLinkId);
      return status;
    } 

    if ( status >= 400 && status < 600 ) {
      const errId = errorIdMessage(status, data);
      handleSubmitError(errId);
      return status;
    } 
}

export const setSessionStatus = (stayOn) => {
  return {
    type: types.SET_SESSION_STATUS,
    payload: stayOn
  }
}
