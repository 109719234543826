import React from 'react';
import * as s from './FormPanel.styles';
import FormMessage from '../FormMessage';
import { colors } from '../../base';


function FormPanel() {
  const itemIconRender = function() {
    return (
      <s.TaskIcon width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24.8821" height="24.7029" rx="12.3515" fill="white" className='portal-branding-filling'/>
        <path d="M5.80273 12.3515L10.7817 17.3305L19.08 7.37256" stroke={colors.newPalette.method.base} className='portal-branding-stroke' strokeWidth="1.65966" strokeLinecap="round" strokeLinejoin="round"/>
      </s.TaskIcon>
    )
  }

  return (
    <s.Panel className='portal-signin-panel'> 
      <s.PanelBackground>    
        <svg style={{ alignSelf: 'end' }} width="126" height="115" viewBox="0 0 126 115" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <circle cx="8.71121" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="24.9182" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="41.1272" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="57.3362" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="73.5432" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="89.7522" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="105.959" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="122.168" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="8.71121" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="24.9182" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="41.1272" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="57.3362" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="73.5432" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="89.7522" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="105.959" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="122.168" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="8.71121" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="24.9182" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="41.1272" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="57.3362" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="73.5432" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="89.7522" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="105.959" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="122.168" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="8.71121" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="24.9182" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="41.1272" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="57.3362" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="73.5432" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="89.7522" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="105.959" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="122.168" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="8.71121" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="24.9182" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="41.1272" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="57.3362" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="73.5432" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="89.7522" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="105.959" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="122.168" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="8.71121" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="24.9182" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="41.1272" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="57.3362" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="73.5432" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="89.7522" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="105.959" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="122.168" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="8.71121" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="24.9182" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="41.1272" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="57.3362" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="73.5432" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="89.7522" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="105.959" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="122.168" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="8.71121" cy="110.757" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="24.9182" cy="110.757" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="41.1272" cy="110.757" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="57.3362" cy="110.757" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="73.5432" cy="110.757" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="89.7522" cy="110.757" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="105.959" cy="110.757" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="122.168" cy="110.757" r="3.60184" fill="white" className='portal-branding-filling'/>
          </g>
        </svg>
        <svg width="125" height="100" viewBox="0 0 125 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <circle cx="4.05207" cy="4.05207" r="4.05207" fill="white" className='portal-branding-filling'/>
            <circle cx="20.7112" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="36.9182" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="53.1272" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="69.3362" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="85.5432" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="101.752" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="117.959" cy="3.60184" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="4.05207" cy="19.3597" r="4.05207" fill="white" className='portal-branding-filling'/>
            <circle cx="20.7112" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="36.9182" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="53.1272" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="69.3362" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="85.5432" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="101.752" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="117.959" cy="18.9095" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="4.05207" cy="34.6678" r="4.05207" fill="white" className='portal-branding-filling'/>
            <circle cx="20.7112" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="36.9182" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="53.1272" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="69.3362" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="85.5432" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="101.752" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="117.959" cy="34.2176" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="4.05207" cy="49.9754" r="4.05207" fill="white" className='portal-branding-filling'/>
            <circle cx="20.7112" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="36.9182" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="53.1272" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="69.3362" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="85.5432" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="101.752" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="117.959" cy="49.5252" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="4.05207" cy="65.2835" r="4.05207" fill="white" className='portal-branding-filling'/>
            <circle cx="20.7112" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="36.9182" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="53.1272" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="69.3362" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="85.5432" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="101.752" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="117.959" cy="64.8333" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="4.05207" cy="80.5911" r="4.05207" fill="white" className='portal-branding-filling'/>
            <circle cx="20.7112" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="36.9182" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="53.1272" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="69.3362" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="85.5432" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="101.752" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="117.959" cy="80.1409" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="4.05207" cy="95.8987" r="4.05207" fill="white" className='portal-branding-filling'/>
            <circle cx="20.7112" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="36.9182" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="53.1272" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="69.3362" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="85.5432" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="101.752" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
            <circle cx="117.959" cy="95.4485" r="3.60184" fill="white" className='portal-branding-filling'/>
          </g>
        </svg>
      </s.PanelBackground> 
      <s.TextBackground>        
        <s.TextContainer> 
            <FormMessage fontSize='28px' fontWeight={600} lineHeight='36px' color={colors['white']} className='portal-branding-font' style={{ marginBottom: 32, textAlign: 'left' }}>
                Welcome to your portal
            </FormMessage>

            <s.TaskItem>
                {itemIconRender()}
                <FormMessage fontSize='18px' fontWeight={600} lineHeight='24px' color={colors['white']} className='portal-branding-font' style={{ marginBottom: 24, textAlign: 'left' }}>
                  Secure and online
                </FormMessage>
            </s.TaskItem>
            <s.TaskItem>
                {itemIconRender()}
                <FormMessage fontSize='18px' fontWeight={600} lineHeight='24px' color={colors['white']} className='portal-branding-font' style={{ marginBottom: 24, textAlign: 'left' }}>
                  Access transaction history
                </FormMessage>
            </s.TaskItem>
            <s.TaskItem>
                {itemIconRender()}
                <FormMessage fontSize='18px' fontWeight={600} lineHeight='24px' color={colors['white']} className='portal-branding-font' style={{ marginBottom: 0, textAlign: 'left' }}>
                  Update personal information
                </FormMessage>
            </s.TaskItem>
        </s.TextContainer>
      </s.TextBackground>      
    </s.Panel>
)}

FormPanel.propTypes = {
};

export default FormPanel;
