import styled from '@emotion/styled';
import { colors, mq } from '../../base';

export const FormLink = styled.div`
    padding-bottom: 16px;
`;

export const Anchor = styled.a`
    margin: 0;
    text-decoration: none;
    color: ${colors['blue']};
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    vertical-align: middle;
    user-select: none; 
`;

export const Svg = styled.svg`
    color:${colors['blue']};
    height: 20px;
    width: 20px;
    padding-right: 8px;
    vertical-align: bottom;
`;

export const Mobile = styled.span`
    ${mq.mobileL} {
        display:none;
    }
`;

export const Desktop = styled.span`
    display: none;

    ${mq.mobileL} {
        display: inherit;
    }
`;