import produce from 'immer';
import * as types from '../constants/actionTypes';

const defaultState = {
  activeForm: null, 
  formErrorId: null
};

const activeFormReducer = (state = defaultState, action) => {
  return produce(state, draft => {
    switch(action.type) {
      case types.SET_ACTIVE_FORM:
        draft.activeForm = action.payload;
        break;

      case types.SET_NO_DATA_ERROR:
        draft.formErrorId = action.payload;
        break;
      default:
        break;
    }
  })
}

export default activeFormReducer;

