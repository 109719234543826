import styled from 'styled-components';
import { colors, mq } from '../../base';

export const LineBreak = styled.div`
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 100%;
    height: 1px;
    margin: 4px 0;
    background-color: ${colors['grey']};

    font-size: 14px;
    line-height: 16px;

    span {
        position: absolute;
        background: white;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 4px;
        color: ${colors['grey']};
        user-select: none;
    }
`;

export const SmallLink = styled.u`
    color: ${colors['blue']};
    text-decoration: none;

    &:hover {
        cursor: pointer;
        color: ${colors['blue-dark']};
    }

    &:active {
        outline: none;
        text-decoration: underline;
        box-shadow: 0 0 0 2px ${colors['white']}, 0 0 0 4px ${colors['blue']};
    }
`;

export const InlineCodeError = styled.div`
    color: red;
    display: flex;
    align-items: center;
    margin: -14px 0px 16px;

    #InlineErrorIcon {
        padding: 0px 4px;
    }

    #InlineErrorText {
        font-size: 12px;
    }
`;

export const MobileFormat = styled.div`
    .enterCodeText {
        font-size: 28px;
        line-height: 36px;
    }

    .formMessageText {
        font-size: 16px;
        line-height: 24px;
    }

    ${mq.mobileL} {
        .enterCodeText {
            font-size: 20px;
            line-height: 24px;
        }
    
        .formMessageText {
            font-size: 14px;
            line-height: 16px;
        }
    }
`;