import styled from '@emotion/styled';
import { mq } from '../../base';

export const Panel = styled.div`
  height: 665px;
  width: 50%;

  background: linear-gradient(210.98deg, #005AA8 1.09%, #3591E0 101.6%);
  border-radius: 0px 12px 12px 0px;

  display: none;
  position: relative;
  
  ${mq.laptop} {
    display: flex;
    width: 495px;
  }
`;

export const PanelBackground = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;  
`;

export const TextContainer = styled.div`

`;

export const TaskItem = styled.div`
  display: flex;
`;

export const TaskIcon = styled.svg`
  margin-right: 24px;
`;


export const TextBackground = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;  

  display: flex;
  align-items: center;
  justify-content: center;
`;
