import axios from 'axios';
import uuid from 'uuid';
import { apiDomain, handleError } from './apiUtils';

export const generateCode = async (AccountId, Email, BrandingColor) => {
  const requestId = uuid();
  let url;

  try {
    url = `${apiDomain()}/authentication/portal/codes`;

    return await axios({
      method: "post",
      url: url,
      headers: { 
        "Content-Type" : "application/json",
        "method-client" : "portal-signin",
        "method-request-id": requestId
      },
      data: {
        AccountId,
        Email,
        BrandingColor
      }
    });
  } 
  catch(error) {
    return handleError(requestId, error, url);
  }
}