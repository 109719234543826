import { checkUser } from "../api/checkPortalUserAPI";
import * as types from '../constants/actionTypes';


// Get portal user with their respective phone for future SMS
export const checkPortalUser = ({
    AccountId,
    userEmail,
    handleSubmitError
}) => async (dispatch) => {
    const { status, data } = await checkUser(AccountId, userEmail);

    if (status >= 200 && status < 300) {
        dispatch(updateUserEmail(userEmail));
        dispatch(setUserPortalData(data.phones, data.sniperLink, data.emailProvider));
    }

    if (status >= 400 && status < 600 && handleSubmitError) {
        handleSubmitError(data); // comes through withFormik
    }

    return status;
};

export const setUserPortalData = (portalUserPhones, portaluserSniperLink, portalUserMXResult) => ({
    type: types.SET_PORTAL_USER_DATA,
    payload: {
        userPhones: portalUserPhones,
        userSniperLink: portaluserSniperLink,
        userEmailProvider: portalUserMXResult
    }
});

export const updateUserEmail = (userEmail) => {
    return {
      type: types.UPDATE_USER_EMAIL,
      payload: userEmail
    }
  }