import React from 'react';
import PropTypes from 'prop-types';
import * as s from './FormCheck.styles';

function FormCheck({name, className, checked, caption, onChange, ...props}) {
    return (
        <s.Label htmlFor={name}>
            <s.CheckboxContainer className={className}>
                <s.HiddenCheckbox 
                    id={name}  
                    name={name} 
                    type="checkbox" 
                    checked={checked} 
                    onChange={onChange} 
                    {...props}        
                />
                <s.StyledCheckbox className={checked ? "portal-branding-checked" : "portal-branding-unchecked"} checked={checked}>
                    <s.Icon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </s.Icon>
                </s.StyledCheckbox>
            </s.CheckboxContainer>
            <s.Caption>{caption}</s.Caption>
        </s.Label>
    );
};

FormCheck.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    checked: PropTypes.bool,
    caption: PropTypes.string,
};

FormCheck.defaultProps = {
    className: "",
    checked: false,
    caption: "",
};

export default FormCheck;