import styled from '@emotion/styled';
import { colors, mq } from '../src/base';

export const PortalSignInWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-basis: 100%;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: stretch;
  background: ${colors['white']};
  overflow: auto;

  ${mq.laptop} {
    background: ${colors['blue-alice']};
  }
`;