import * as yup from 'yup';
import { logError } from './errorLogger';

const runValidation = (schema, data) => {
  schema.validate(data)
  .catch((e)=> {
    logError({
      error: `(${e.type}) ${e.message}`
    });
  });
}

// log an error if portal data model is not valid
export const validatePortalDataSchema = (data = {}) => { 
  const schema = yup.object().shape({
    AccountId: yup.number().strict().required(),
    Name: yup.string().strict().required(),
    PortalCompanyName: yup.string().strict(),
    PortalContactEmail: yup.string().strict(),
    PortalLogo: yup.string().strict(),
    PortalName: yup.string().strict(),
    isPortalEnabled: yup.boolean().strict().required(),
    isPortalRenamed: yup.boolean().strict(),
  });

  runValidation(schema, data);
  
}

// log an error if cookie tokens model is not valid
export const validateTokenSchema = (data = {}) => {
  const schema = yup.object().shape({
    portalSession: yup.object().shape({
      sessionToken: yup.string().strict().required(),
      accessTokenV2: yup.string().strict().required(),
    }).strict().required()
  })

  runValidation(schema, data);
}