import styled from '@emotion/styled';
import { colors } from '../../base';

export const PoweredBy = styled.div`
  padding: 2px 0;
  margin-top: 46px;

  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Anchor = styled.a`
  height: 12px;
  margin-left: 2px;
  text-decoration: none;
`;

export const Label = styled.span`
  color: ${colors['black']};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  user-select: none;
`;

export const Svg = styled.svg`
  color: ${colors['method']};
  height: 12px;
  width: 51px;
  vertical-align: top;
`;