import { generateCode } from "../api/generateCodeAPI";

// Create the portal code and email it after user enters the email
export const generatePortalCode = ({AccountId, userEmail, handleSubmitError, BrandingColor }) => async (dispatch) => {
    // const {status, data} = await mockGenerateCode();
    const {status, data} =  await generateCode(AccountId, userEmail, BrandingColor);
    
    if ( status >= 400 && status < 600 && handleSubmitError ) {
      handleSubmitError(data); // comes through withFormik
    }

    return status;
};

