import React from 'react';
import PropTypes from 'prop-types';
import * as s from './FormButton.styles';

function FormButton({ buttonStyle = 'primary', buttonText, onClick, type, disabled, icon }) {
    return (
      <s.Button
        type={type}
        onClick={onClick}
        buttonStyle={buttonStyle}
        disabled={disabled}
        className={`portal-branding-button ${buttonStyle}`}
      >
      {icon && String(icon).toLowerCase() === 'google' &&
        <s.ButtonIcon width={19} height={13}>
          <path d="M4.59092 12.936V6.27368L2.43409 4.38343L0.5 3.33447V11.7603C0.5 12.4108 1.0502 12.936 1.7273 12.936H4.59092Z" fill="#4285F4"/>
          <path d="M14.4091 12.9359H17.2727C17.9518 12.9359 18.5 12.4088 18.5 11.7602V3.33447L16.3093 4.53593L14.4091 6.27362V12.9359Z" fill="#34A853"/>
          <path d="M4.59097 6.27385L4.29749 3.67069L4.59097 1.1792L9.50005 4.70631L14.4091 1.1792L14.7374 3.53617L14.4091 6.27385L9.50005 9.80096L4.59097 6.27385Z" fill="#EA4335"/>
          <path d="M14.4091 1.17901V6.27366L18.5 3.33445V1.76683C18.5 0.312922 16.7675 -0.515912 15.5546 0.356031L14.4091 1.17901Z" fill="#FBBC04"/>
          <path d="M0.5 3.33448L2.38149 4.68634L4.59092 6.27369V1.17904L3.44539 0.356062C2.23039 -0.515949 0.5 0.312953 0.5 1.7668V3.33441V3.33448Z" fill="#C5221F"/>
        </s.ButtonIcon>
      }
      {icon && String(icon).toLowerCase() === 'google-2' &&
        <s.ButtonIcon width={20} height={21}>
          <path d="M19.6002 10.3067C19.6002 9.59759 19.5366 8.91578 19.4184 8.26123H10.0002V12.1294H15.3821C15.1502 13.3794 14.4457 14.4385 13.3866 15.1476V17.6567H16.6184C18.5093 15.9158 19.6002 13.3521 19.6002 10.3067Z" fill="#4285F4"/>
          <path d="M10.0003 20.0795C12.7003 20.0795 14.964 19.184 16.6185 17.6568L13.3867 15.1477C12.4912 15.7477 11.3458 16.1022 10.0003 16.1022C7.39578 16.1022 5.19124 14.3431 4.40487 11.9795H1.06396V14.5704C2.70942 17.8386 6.09124 20.0795 10.0003 20.0795Z" fill="#34A853"/>
          <path d="M4.40479 11.9798C4.20479 11.3798 4.09115 10.7389 4.09115 10.0798C4.09115 9.42069 4.20479 8.77978 4.40479 8.17978V5.58887H1.06388C0.386608 6.93887 0.000244141 8.46614 0.000244141 10.0798C0.000244141 11.6934 0.386608 13.2207 1.06388 14.5707L4.40479 11.9798Z" fill="#FBBC05"/>
          <path d="M10.0003 4.05686C11.4685 4.05686 12.7867 4.56141 13.8231 5.55232L16.6912 2.68414C14.9594 1.0705 12.6958 0.0795898 10.0003 0.0795898C6.09124 0.0795898 2.70942 2.3205 1.06396 5.58868L4.40487 8.17959C5.19124 5.81595 7.39578 4.05686 10.0003 4.05686Z" fill="#EA4335"/>
        </s.ButtonIcon>
      }
      {icon && String(icon).toLowerCase() === 'microsoft' &&
        <s.ButtonIcon width={20} height={21}>
          <path d="M9.47368 0.0795898H0V9.55327H9.47368V0.0795898Z" fill="#F25022"/>
          <path d="M9.47368 10.606H0V20.0796H9.47368V10.606Z" fill="#00A4EF"/>
          <path d="M20.0001 0.0795898H10.5264V9.55327H20.0001V0.0795898Z" fill="#7FBA00"/>
          <path d="M20.0001 10.606H10.5264V20.0796H20.0001V10.606Z" fill="#FFB900"/>
        </s.ButtonIcon>
      }
      {icon && String(icon).toLowerCase() === 'facebook' &&
        <s.ButtonIcon width={23} height={24}>
          <path fillRule="evenodd" clipRule="evenodd" d="M-0.000488281 11.7922C-0.000488281 17.5097 4.15197 22.2639 9.58284 23.228V14.9222H6.70784V11.728H9.58284V9.17215C9.58284 6.29715 11.4353 4.70057 14.0554 4.70057C14.8853 4.70057 15.7804 4.82803 16.6103 4.95549V7.89469H15.1412C13.7353 7.89469 13.4162 8.59715 13.4162 9.49224V11.728H16.4828L15.9721 14.9222H13.4162V23.228C18.8471 22.2639 22.9995 17.5106 22.9995 11.7922C22.9995 5.43178 17.8245 0.228027 11.4995 0.228027C5.17451 0.228027 -0.000488281 5.43178 -0.000488281 11.7922Z" fill="#1877F2"/>
        </s.ButtonIcon>
      }
      {icon && String(icon).toLowerCase() === 'yahoo' &&
        <s.ButtonIcon width={24} height={24}>
          <path d="M10.5 7.5898L8.16 13.1998L5.85 7.5898H2L6.29 17.2298L4.75 20.6998H8.5L14.24 7.5898H10.5ZM15 12.7298C13.63 12.7298 12.59 13.7698 12.59 14.9998C12.59 16.1698 13.59 17.1598 14.93 17.1598C16.32 17.1598 17.36 16.1298 17.36 14.8998C17.36 13.6898 16.36 12.7298 15 12.7298ZM17.72 3.2998L13.89 11.8898H18.17L22 3.2998H17.72Z" fill="#950FFF"/>
        </s.ButtonIcon>
      }
        {buttonText}
      </s.Button>
    );
  }
  
  FormButton.propTypes = {
    buttonStyle: PropTypes.string,
    buttonText: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    icon: PropTypes.string,
  };
  
  FormButton.defaultProps = {
    buttonStyle: 'primary',
    buttonText: '',
    onClick: null
  };
  
  export default FormButton;