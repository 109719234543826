import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '../../base';

export const BoxKeyframes = keyframes`
  0% {     
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background-color: ${colors['white']};
  align-items: center;
  justify-content: center;
  overflow: hidden; 
`;

export const InnerContainer = styled.div`
  display: block;  
`;

export const SvgContainer = styled.div`
  display: flex;
  padding-bottom: 5px;

  animation-name: ${BoxKeyframes};
  animation-duration: 0.7s;
  animation-timing-function: steps(8, end);
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`;

export const Svg = styled.svg`
  margin: auto;
`;
