import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from "formik";
import { sendCode } from "../withFormik";

import FormButton from '../../components/FormButton';
import FormMessage from '../../components/FormMessage';
import FormError from '../../components/FormError';
import FormTitle from '../../components/FormTitle';
import ChannelBox from '../../components/ChannelBox';

const EMAIL = 'email';
const PHONE = 'phone';
const PICKED = 'picked';

function SendCodeForm({ 
    values, 
    errors, 
    isSubmitting, 
    setFieldValue, 
    phone
}) {
    const [selected, setSelected] = useState(EMAIL);
    
    useEffect(() => {
      setFieldValue(PHONE, phone);
    }, [phone]);

    return (
        <Form style={{width: '100%', maxWidth: '100%'}} >
            <FormTitle titleText='Sign in' />
            {errors.title && (
                <FormError errorMessage={errors.title} errorId={errors.message} />
            )}
            <FormMessage fontSize='18px' fontWeight={600} lineHeight='24px' style={{ marginBottom: '4px'}}>
                Sign in instanly with a secure code
            </FormMessage>
            <FormMessage fontSize='14px' fontWeight={400} lineHeight='16px' style={{ marginBottom: '16px'}}>
                How you would like to receive code?
            </FormMessage>
            <ChannelBox 
                id={EMAIL} 
                value={values[EMAIL]} 
                onClick={() => { setSelected(EMAIL); }} 
                icon={EMAIL} 
                title='Email'
                values={[`${values[EMAIL]}`]} 
                isSelected={selected === EMAIL}
            />
            {values[PHONE] && 
                <ChannelBox 
                id={PHONE} 
                value={values[PHONE]} 
                onClick={() => { setSelected(PHONE); }} 
                icon={PHONE} 
                title='SMS' 
                values={[`${values[PHONE]}`]}
                isSelected={selected === PHONE}
                />
            }

            <FormButton
                type='submit'
                disabled={isSubmitting || !values[EMAIL]}
                buttonText='Send code'
                onClick={() => setFieldValue(PICKED, selected)}
            />
        </Form>
    )
};

SendCodeForm.propTypes = {
    errors: PropTypes.instanceOf(Object),
    isSubmitting: PropTypes.bool,
    values: PropTypes.instanceOf(Object),
    setFieldValue: PropTypes.func, 
    phone: PropTypes.number,
}

export default sendCode(SendCodeForm);