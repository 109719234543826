import styled from "@emotion/styled";
import { colors, mq } from "../../base";

export const GeneralBox = styled.div`
    display: flex;
    justify-content: flex-start;
    border: solid ${({ isSelected }) => (isSelected) ? colors['blue'] : colors['grey-light']};
    background-color: ${({ isSelected }) => (isSelected) ? colors['blue-alice'] : ``};
    border-width: 2px;
    border-radius: 15px;
    overflow: hidden;
    padding: 0 2px;

    &:hover{
        background-color: ${colors['blue-alice']};
        cursor: pointer;
    }
    
    ${mq.mobileL} {
        margin-bottom: 16px;
        & img {
            max-height: 112px;
        }
    }

    ${mq.mobileL} {
        & img {
            max-height: 148px;
        }
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
`;
