import styled from '@emotion/styled';
import { colors } from '../../base';

export const InputBox = styled.div`
  max-width: 384px;
  width: 100%;
  margin: auto;
  margin-bottom: 16px;
  display: flex;
  position: relative;

  .Active {
      transform: translate(0, -18px) scale(0.75);
  }

  label {
      transform-origin: top left;
      transition: all 0.2s ease-out;
  }
  
  :focus-within label {
      transform: translate(0, -18px) scale(0.75);
  }
`;

export const Label = styled.label`
  display: block;

  text-align: left;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  user-select: none;
  position: absolute;
  top: 11px;
  left: 7px;
  background: ${colors['white']};
  color: ${({ hasError, isStatic }) => (hasError && !isStatic) ? colors['red'] : colors['grey-dark']};

  .Active {
      transform: translate(0, 12px) scale(0.75);
  }
`;

export const Input = styled.input`
  box-sizing: border-box;
  display: block;
  
  width: 100%;
  height: 38px !important;
  margin: 0px !important;

  background: ${({ isStatic }) => isStatic ? colors['concrete'] : colors['white']} !important;
  border-radius: 3px !important;
  border: 1px solid ${({ hasError }) => (hasError) ? colors['red'] : colors['grey']};
  border-width: '1px';
  
  padding:'12px 8px' !important;

  ${({ isStatic }) =>
    isStatic &&
    `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
      
  font-size: 16px !important;
  padding: 12px 8px;
  color: ${colors['grey-dark']};
  
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-expand: none;
  -webkit-font-smoothing: antialiased;

  &:focus, &:hover, &:active {
      border: 1px solid ${({ hasError }) => hasError ? colors['fuchsia-dark'] : colors['blue']};
      outline: none;
  }    
`;