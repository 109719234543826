import React from 'react';
import PropTypes from 'prop-types';
import * as s from './FormMessage.styles';

function FormMessage({ type = '', className = '', fontWeight, lineHeight, fontSize, color, style, children }) {
  return <s.FormMessage className={className} fontWeight={fontWeight} lineHeight={lineHeight} fontSize={fontSize} color={color} style={style} type={type}>{children}</s.FormMessage>;
}

FormMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  type: PropTypes.string,
  fontWeight: PropTypes.number, 
  lineHeight: PropTypes.string, 
  fontSize: PropTypes.string, 
  color: PropTypes.string, 
  style: PropTypes.instanceOf(Object), 
};

export default FormMessage;