import axios from 'axios';
import { apiDomain, handleError } from './apiUtils';
import uuid from 'uuid';

export const checkUser = async (AccountId, Email) => {
  const requestId = uuid();
  let url;

  try {
    url = `${apiDomain()}/authentication/portal/portaluser`;

    return await axios({
      method: 'post',
      url: url,
      headers: {
        "Content-Type" : "application/json",
        "method-client" : "portal-signin",
        "method-request-id": requestId
      },
      data: {
        AccountId,
        Email
      }
    });
  } catch (error) {
    return handleError(requestId, error, url);
  }
};