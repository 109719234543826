import React from 'react';
import PropTypes from 'prop-types';
import * as s from './FormContainer.styles';
import FormPanel from '../FormPanel/FormPanel';

function FormContainer({
    id,
    children,
  }) {
    return (
      <s.FormContainer id={`${id}-form`}>
        <s.Box>
          <s.FormContent>{children}</s.FormContent>
        </s.Box>
        <FormPanel />
      </s.FormContainer>
    )
  }
  
  FormContainer.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    companyName: PropTypes.string,
    companyLogo: PropTypes.string,
    id: PropTypes.string,
  };
  
  export default FormContainer;