import styled from '@emotion/styled';
import { colors, mq } from '../../base';

export const FormMessage = styled.h2`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  margin-bottom: 32px;

  font-size: ${({ fontSize }) => (fontSize) ? `${fontSize} !important` : '16px'};
  line-height: ${({ lineHeight }) => (lineHeight) ? `${lineHeight} !important` : '24px'};
  font-weight: ${({ fontWeight }) => (fontWeight) ? `${fontWeight} !important` : 'normal'};
  text-align: center;
  color: ${({ color }) => (color) ? `${color} !important` : colors['method']};

  ${({ type }) =>
    type === 'error' &&
    `
        background-color: #FCEBEF; 
        border-radius: 3px;
        padding: 8px 12px; 
        text-align: left;
        margin-bottom: 16px;
        
        color: ${colors['fuchsia-darker']} !important;

        ${mq.mobileL} {
            padding: 12px 16px;
        }
    `}

  ${(props) => props.type === 'unavailable' && 'margin: 0'};
`;