/* This is obtained from https://css-tricks.com/converting-color-spaces-in-javascript/ */
/* Hex to HSL */
export function hexToHSL(H) {
    // Convert hex to RGB first
    let r = 0;
    let g = 0;
    let b = 0;

    if (H.length === 4) {
      r = `0x${H[1]}${H[1]}`;
      g = `0x${H[2]}${H[2]}`;
      b = `0x${H[3]}${H[3]}`;
    } else if (H.length === 7) {
      r = `0x${H[1]}${H[2]}`;
      g = `0x${H[3]}${H[4]}`;
      b = `0x${H[5]}${H[6]}`;
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;

    const cmin = Math.min(r,g,b);
    const cmax = Math.max(r,g,b);
    const delta = cmax - cmin;

    let h = 0;
    let s = 0;
    let l = 0;
  
    if (delta === 0)
      h = 0;
    else if (cmax === r)
      h = ((g - b) / delta) % 6;
    else if (cmax === g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;
  
    h = Math.round(h * 60);
  
    if (h < 0)
      h += 360;
  
    l = (cmax + cmin) / 2;
    s = (delta === 0) ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
  
    return { h, s, l };
}

export function hexToHSLString(H) {    
    const { h, s, l } = hexToHSL(H);
    return `hsl(${h}, ${s}%, ${l}%)`;
}

/* HSL to Hex */
export function HSLToHex(hue,saturation,light) {
    const h = hue;
    const s = saturation/100;
    const l = light/100;
  
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c/2;

    let r = 0;
    let g = 0;
    let b = 0; 
  
    if (h >= 0 && h < 60) {
      r = c; g = x; b = 0;
    } else if (h >= 60 && h < 120) {
      r = x; g = c; b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0; g = c; b = x;
    } else if (h >= 180 && h < 240) {
      r = 0; g = x; b = c;
    } else if (h >= 240 && h < 300) {
      r = x; g = 0; b = c;
    } else if (h >= 300 && h < 360) {
      r = c; g = 0; b = x;
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);
  
    // Prepend 0s, if necessary
    if (r.length === 1)
      r = `0${r}`;
    if (g.length === 1)
      g = `0${g}`;
    if (b.length === 1)
      b = `0${b}`;
  
    return `#${r}${g}${b}`;
}

export function HSLToHexFromString(hslString) { 
    const sep = hslString.indexOf(",") > -1 ? "," : " ";
    const hsl = hslString.substr(4).split(")")[0].split(sep);
  
    let h = hsl[0];
    const s = hsl[1].substr(0, hsl[1].length - 1) / 100;
    const l = hsl[2].substr(0, hsl[2].length - 1) / 100;
          
    // Strip label and convert to degrees (if necessary)
    if (h.indexOf("deg") > -1)
      h = h.substr(0,h.length - 3);
    else if (h.indexOf("rad") > -1)
      h = Math.round(h.substr(0,h.length - 3) * (180 / Math.PI));
    else if (h.indexOf("turn") > -1)
      h = Math.round(h.substr(0,h.length - 4) * 360);
    if (h >= 360)
      h %= 360;
  
    return HSLToHex(h, s, l);
}

export function ShadeAndTintHex(hex, value) {
    const { h, s, l } = hexToHSL(hex);

    const lowerLimit = (l - value) < 0 ? 0 : (l - value);
    const upperLimit = (l + value) > 99 ? 99 : (l + value);

    const lowerColor = HSLToHex(h, s, lowerLimit);
    const upperColor = HSLToHex(h, s, upperLimit);

    return { lowerColor, upperColor };
}
