import { portalDomain, mainDomain } from "./urls";

export const redirecToSignIn = (account) => {
  const domain = (mainDomain() === 'methodlocal.com' && portalDomain() !== 'methodportallocal.com')
    ? `http://localhost:3000/${account}`
    : `https://signin.${portalDomain()}/${account}`;
  window.location = domain;
  return domain;
}


export const redirectToSales = () => {
  const domain = `https://www.${mainDomain()}`;
  window.location = domain;
  return domain;
}

export const redirectToPortal = (portalName, accountLinkId) => {
  const domain = (mainDomain() === 'methodlocal.com')
    ? `https://${portalName}.methodportallocal.com/apps/${accountLinkId}#/`
    : `https://${portalName}.${portalDomain()}/apps/${accountLinkId}#/`;

  window.location = domain;
  return domain;
}
