import { mainDomain } from "../helpers/urls";
import { logError } from "./errorLogger";

/*-----------------
 * ApiDomain
 *---------------*/

export const apiDomain = () => {
  let domain = `https://api.${mainDomain()}`;

  if (mainDomain() === 'methodlocal.com') {
    domain += '/v2';
  }
  
  return domain;
};


/*-----------------
 * HandleResponse
 *
 * required for axios inceptors 
 *---------------*/
export const handleResponse = (response) => {
  return response;
};


/*-----------------
 * HandleError
 *
 * axios inceptors only accept function references.
 * so we need to use closure to give handleError access to requestId 
 *---------------*/
export const handleError = (requestId, error, query) => {
  
  logError({
    error: `${error.message}`,
    requestId,
    query
  });

  if (!error.response) {
    error.response = {
      status: error.status || 400,
      data: requestId
    }
  }

  if (error.response.status >= 400) error.response.requestId = requestId;  
  // return Promise.reject(error.response);
  return error.response;
};