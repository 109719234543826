import styled from '@emotion/styled';
import { mq } from '../../base';

export const Back = styled.div`
  cursor: pointer;
  height: fit-content;
  margin-top: 25px;

  ${mq.mobileL} {
    margin-top: 0px;
  }
`;

export const Next = styled.div`
  cursor: pointer;
  height: fit-content;
  margin-top: 25px;

  ${mq.mobileL} {
    margin-top: 0px;
  }
`;

export const NavContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 38px 24px;
  display: flex;
  justify-content: space-between;
  
  ${mq.laptop} {
    padding: 38px 33px;
  }
`;