import React from 'react';
import PropTypes from 'prop-types';
import * as s from './FormLink.styles';

function FormLink({ email, name, onClick  }) {
    if( Boolean(email) ){
        return (
            <s.FormLink onClick={onClick} >
                <s.Anchor target="_blank" href={`mailto:${email}`} onclick="">
                    <s.Svg viewBox="0 0 1024 1024">
                        <path fill="currentColor" d="M896 170.667h-768c-47.061 0-85.333 38.272-85.333 85.333v469.333c0 47.061 38.272 85.333 85.333 85.333h768c47.061 0 85.333-38.272 85.333-85.333v-469.333c0-47.061-38.272-85.333-85.333-85.333zM896 213.333l6.784 1.365-390.784 312.661-390.784-312.661 6.784-1.365h768zM938.667 725.333c0 23.509-19.157 42.667-42.667 42.667h-768c-23.509 0-42.667-19.157-42.667-42.667v-469.333c0-4.693 1.28-8.96 2.688-13.184l410.667 328.533c3.883 3.072 8.619 4.651 13.312 4.651s9.429-1.579 13.312-4.651l410.667-328.533c1.408 4.224 2.688 8.491 2.688 13.184v469.333z"></path>
                    </s.Svg>
                    <s.Desktop>{`Contact ${name}`}</s.Desktop>
                    <s.Mobile>Contact Us</s.Mobile>
                </s.Anchor>
            </s.FormLink>
        );
    } else {
        return null;
    }
}

FormLink.propTypes = {
    email: PropTypes.string,
    name: PropTypes.string
}

FormLink.defaultProps = {
    name: "Us",
    email: "",
    onClick: null
}

export default FormLink;