import React from 'react';
import FormMessage from '../FormMessage';
import * as s from './FormLoader.styles';

function FormLoader() {
    return (
        <s.Container>
            <s.InnerContainer>
                <FormMessage fontSize='40px' fontWeight={600} lineHeight='49px' style={{ marginBottom: 16 }}>
                    Welcome!
                </FormMessage>
                <FormMessage fontSize='24px' fontWeight={600} lineHeight='32px' style={{ marginBottom: 24 }}>
                    We are signing you in to your portal
                </FormMessage>
                <s.SvgContainer>
                    <s.Svg width="65" height="64" viewBox="0 0 65 64" fill="none">
                        <path d="M54.7788 29.3043C59.9989 29.3043 64.2305 25.0726 64.2305 19.8526C64.2305 14.6325 59.9989 10.4009 54.7788 10.4009C49.5588 10.4009 45.3271 14.6325 45.3271 19.8526C45.3271 25.0726 49.5588 29.3043 54.7788 29.3043Z" fill="#0D71C8"/>
                        <path d="M35.8759 17.1516C40.3502 17.1516 43.9773 13.5245 43.9773 9.05018C43.9773 4.57587 40.3502 0.94873 35.8759 0.94873C31.4016 0.94873 27.7744 4.57587 27.7744 9.05018C27.7744 13.5245 31.4016 17.1516 35.8759 17.1516Z" fill="#0D71C8"/>
                        <path d="M16.9721 22.553C21.4464 22.553 25.0735 18.9259 25.0735 14.4515C25.0735 9.97724 21.4464 6.3501 16.9721 6.3501C12.4977 6.3501 8.87061 9.97724 8.87061 14.4515C8.87061 18.9259 12.4977 22.553 16.9721 22.553Z" fill="#0D71C8"/>
                        <path d="M7.52074 37.4057C11.2493 37.4057 14.2719 34.3831 14.2719 30.6545C14.2719 26.9259 11.2493 23.9033 7.52074 23.9033C3.79215 23.9033 0.769531 26.9259 0.769531 30.6545C0.769531 34.3831 3.79215 37.4057 7.52074 37.4057Z" fill="#0D71C8"/>
                        <path d="M11.5715 53.6084C15.3001 53.6084 18.3227 50.5858 18.3227 46.8572C18.3227 43.1286 15.3001 40.106 11.5715 40.106C7.84293 40.106 4.82031 43.1286 4.82031 46.8572C4.82031 50.5858 7.84293 53.6084 11.5715 53.6084Z" fill="#0D71C8"/>
                        <path d="M25.0738 63.0602C28.0567 63.0602 30.4748 60.6421 30.4748 57.6593C30.4748 54.6764 28.0567 52.2583 25.0738 52.2583C22.0909 52.2583 19.6729 54.6764 19.6729 57.6593C19.6729 60.6421 22.0909 63.0602 25.0738 63.0602Z" fill="#0D71C8"/>
                        <path d="M39.5888 62.1039C41.826 62.1039 43.6395 60.2903 43.6395 58.0532C43.6395 55.816 41.826 54.0024 39.5888 54.0024C37.3517 54.0024 35.5381 55.816 35.5381 58.0532C35.5381 60.2903 37.3517 62.1039 39.5888 62.1039Z" fill="#0D71C8"/>
                    </s.Svg>
                </s.SvgContainer>
            </s.InnerContainer>
        </s.Container>
    );
}

FormLoader.propTypes = {
};

FormLoader.defaultProps = {
};

export default FormLoader;