import produce from 'immer';
import { colors } from '../base';
import * as types from '../constants/actionTypes';

const defaultState = {
  AccountId: null,
  PortalName: "",
  Name: "",
  portalQueryCode: "",
  portalSsoCode: "",  
  isPortalEnabled: false,
  PortalCompanyName: "",
  PortalLogo: "",
  PortalContactEmail: "",
  PortalLogoSignInVisible: false,
  PortalLogoHeaderVisible: false,
  PortalBrandingColor: colors.newPalette.method.base,
  userEmail: "",
  stayOn: false,
  userPhones: [],
  userSniperLink: '',
  userEmailProvider: '',
};

const portalDataReducer = (state = defaultState, action) => {
  return produce(state, draft => {
    switch(action.type) {
      case types.UPDATE_PORTAL_DATA:
        Object.assign(draft, action.payload.data)
        break;
      case types.UPDATE_USER_EMAIL:
        draft.userEmail = action.payload
        break;
      case types.SET_SESSION_STATUS:
        draft.stayOn = action.payload
        break;
      case types.SET_PORTAL_CODE:
        draft.portalQueryCode = action.payload
        break;
      case types.SET_PORTAL_SSO_CODE:
        draft.portalSsoCode = action.payload
        break;
      case types.SET_PORTAL_USER_DATA:
        draft.userPhones = action.payload.userPhones;
        draft.userSniperLink = action.payload.userSniperLink;
        draft.userEmailProvider = action.payload.userEmailProvider;
        break;
      default:
        break;
    }
  })
}

export default portalDataReducer;