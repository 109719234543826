import axios from 'axios';
import uuid from 'uuid';
import { findBrowserType } from '../helpers/browserDetection';
import { portalDomain, toUrlEncoded} from '../helpers/urls';

export const logError = async ( {requestId, error, query }) => {
  
  const logData = {
    requestid: requestId || uuid(),
    url: window.location.href,
    browser: findBrowserType(),
    account: window.portalAccount,
    error: error,
    query: query
  };

  if (process.env.NODE_ENV !== 'test') {

    if (window._ENVIRONMENT === "LOCALMACHINE") {
      console.log(logData);
    }

    try {
      await axios({
        method: "post",
        // url: `https://signin.methodportalbuilder.com/health/log/`,
        url: `https://signin.${portalDomain()}/health/log/`,
        headers: { 
          "Content-Type" : "application/x-www-form-urlencoded;charset=UTF-8",
        },
        data: toUrlEncoded(logData)
      });
    } 
    catch(error) {
      error.status = 'status' in error ? error.status : 400;
      console.error(error)
    }
  }
}
