/*********************************************************
gets the value of a cookie
**********************************************************/

export const getCookie = (sName) => {
    sName = sName.toLowerCase();
    let oCrumbles = document.cookie.split('; ');
    for(let i=0; i<oCrumbles.length;i++)
    {
        let cookieKey = oCrumbles[i].substring(0, oCrumbles[i].indexOf('='));
        let cookieValue = oCrumbles[i].substring(oCrumbles[i].indexOf('=')+1);
        let oPair = [cookieKey, cookieValue];
        let sKey = decodeURIComponent(oPair[0].trim().toLowerCase());
        let sValue = oPair.length>1?oPair[1]:'';
        if(sKey == sName)
            return decodeURIComponent(sValue);
    }
    return '';
}
/*********************************************************
sets the value of a cookie
**********************************************************/
export const setCookie = (sName, sValue, hasExpiry, setExpired, daysToExpire) => {
    let sCookie = `${sName}=${sValue}`;
    let isChrome = window.navigator.userAgent.indexOf("Chrome") > -1;
    let isSafari = window.navigator.userAgent.indexOf("Safari") > -1;
    let isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;

    if (isChrome && isSafari) {
      isSafari = false;
    }

    if (hasExpiry) {
      let oDate = new Date();
      if (setExpired){
        oDate.setYear(oDate.getFullYear() - 1);
      }
      else if (daysToExpire) {
        oDate.setDate(oDate.getDate() + daysToExpire);
      }
      else {
        oDate.setYear(oDate.getFullYear() + 1);
      }
      
      sCookie += `;expires=${oDate.toGMTString()}`;
    }
    sCookie += ';path=/';
    sCookie += `;domain=${window._PORTAL_DOMAIN}`;
    
    //safari doesnt support samesite attribute
    if (!isSafari && !isIOS) {
      sCookie += ';SameSite=None; Secure';
    }
    window.document.cookie= sCookie;
    return sCookie;
}
/*********************************************************
removes the value of a cookie and expires it
**********************************************************/

export const clearCookie = (sName) => {
    setCookie(sName, '', true, true);
}



/* export const clearCookie = (sName) => {
    let sCookie = `${encodeURIComponent(sName)}=`;
    let sDate = ';expires=';
    
    sName = sName.toLowerCase();
    let oCrumbles = document.cookie.split(';');
    for(let i=0; i<oCrumbles.length;i++)
    {
        let oPair= oCrumbles[i].split('=');
        let sKey = decodeURIComponent(oPair[0].trim().toLowerCase());
        let sValue = (oPair.length > 1) ? oPair[1] : '';
        if (sKey == 'expires')
            sDate += sValue;
    }
    
    sCookie += sDate;
    window.document.cookie = sCookie;

} */