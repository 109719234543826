import React from 'react';
import PropTypes from 'prop-types';
import * as s from './ChannelBox.styles';
import FormMessage from '../FormMessage';
import FormIcon from '../FormIcon';

function ChannelBox ({ icon, title, value, onClick, isSelected }) {
    return (
        <s.GeneralBox onClick={onClick} isSelected={isSelected}>
            <FormIcon icon={icon} isSelected={isSelected}/>
            <s.TextContainer>                
                <FormMessage fontSize='16px' fontWeight={600} lineHeight='24px' style={{ marginBottom: 0, textAlign: 'left' }}>
                    {title}
                </FormMessage>           
                <FormMessage fontSize='14px' fontWeight={400} lineHeight='16px' style={{ marginBottom: 0, textAlign: 'left' }}>
                    {value}
                </FormMessage> 
            </s.TextContainer>
        </s.GeneralBox>
    );
};

ChannelBox.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
}

ChannelBox.defaultProps = {
    title: 'title',
    value: 'value',
};

export default ChannelBox;