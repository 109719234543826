import { withFormik } from "formik";
import * as Yup from 'yup';
import { handleEnterEmailError, handleSendCodeError, handleValidateCodeError } from "../helpers/errors";

/*--------------------------------
 *  ENTER EMAIL FORM LOGIC
 * ------------------------------*/
export const enterEmail = (component) =>
  withFormik({
    mapPropsToValues({ email }) {
      return {
        email: email || ''
      };
    },

    handleSubmit: async (values, { setErrors, setSubmitting, setStatus, props }) => {
      const {email} = values;

      // must show errorId after api call failure.
      // it is easier to get the errorId directly from the response
      // instead of redux. so we pass in this callback function to thunk action
      // which gets errorId as a param when axios call fails
      const handleSubmitError = (plainError) => {
        handleEnterEmailError(plainError, setErrors);
      }

      const reqStatus = await props.handleEnterEmail(email, handleSubmitError); // callback for api-call action
      
      if (reqStatus !== 200) {
        setSubmitting(false); // must keep the button disabled while request is being processed
      }
    },

    validateOnChange: false,

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Please enter a valid email address')
        .required(
          'Please enter your email address. We need this to send you the sign in code'
        )
    })
  })(component);

/*--------------------------------
 *  SEND CODE FORM LOGIC
 * ------------------------------*/
export const sendCode = ( component ) => withFormik({
  mapPropsToValues({ email, phone }) {
    return {
      email: email || '',
      phone: phone || '',
    }
  },

  handleSubmit: async (values, { setErrors, setSubmitting, props} ) => {
    const {phone, picked} = values;

    // must show errorId after api call failure.
    // it is easier to get the errorId directly from the response
    // instead of redux. so we pass in this function to thunk action 
    // which gets errorId as a param when axios call fails
    const handleSubmitError = (plainError) => {
      handleSendCodeError(plainError, setErrors);
    }
    
    const reqStatus = await props.handleSendEmail(phone, picked, handleSubmitError);

    if (reqStatus !== 200) {
      setSubmitting(false); // must keep the button disabled while request is being processed
    }
  },

  validateOnChange: false,

  validationSchema: Yup.object().shape({
    email: Yup.string()
              .email('Please enter a valid email address')
              .required('Please enter your email address. We need this to send you the sign in code'),
  })
})(component);


/*--------------------------------
 *  ENTER CODE FORM LOGIC
 * ------------------------------*/
export const enterCode = ( component ) => withFormik({
  mapPropsToValues({ portalCode, stayOn, queryCode }) {
    return {
      portalCode: portalCode || queryCode,
      stayOn: stayOn || false,
    }
  },

  handleSubmit: async (values, { setErrors, setSubmitting, setStatus, props }) => {
    const {portalCode, stayOn} = values;

    // must show errorId after api call failure.
    // it is easier to get the errorId directly from the response
    // instead of redux. so we pass in this function to thunk action 
    // which gets errorId as a param when axios call fails
    const handleSubmitError = (plainError) => {
      handleValidateCodeError(plainError, setErrors, setStatus);
    }

    const reqStatus = await props.handleSendCode(
      portalCode, 
      stayOn, 
      '',
      handleSubmitError
    );

    // must keep the button disabled while request is being processed 
    // or response is 200 and sign-in is in process
    if (reqStatus !== 200) {
      setSubmitting(false); 
    }

  },

  validateOnChange: false,

  validationSchema: Yup.object().shape({
    portalCode: Yup.string()
      .matches(/^[0-9]+$/, "Please enter only digits for the code.")
      .min(6, 'Code must be exactly 6 digits.')
      .max(6, 'Code must be exactly 6 digits.')
      .required('Please enter your verification code'),
    stayOn: Yup.mixed().oneOf([true, false]),
  })
})(component);