import axios from 'axios';
import uuid from 'uuid';
import { apiDomain, handleError} from './apiUtils';

export const sendAnalytics = async (EventName, AccountId, AccountName, isPortalActive, isAccountActive) => {
  const requestId = uuid();
  const sref = ((document.referrer === "") ? window.location.href : document.referrer);
  const isIFrame = ((window.location !== window.parent.location) ? true : false);
  let url;

  try { 
    url = `${apiDomain()}/analytics/events/`;

    return await axios({
      method: "post",
      url: url, 
      headers: { 
        "Content-Type" : "application/json",
        "method-client" : "portal-signin",
        "method-request-id": requestId
      },
      data: {
        "userId": AccountId + ".Portal",
        "name": EventName,
        "accountName": AccountName,
        "customAttributes" : {
            "applicationType" : "portal-signin",
            "isPortalActive" : isPortalActive,
            "isAccountActive" : isAccountActive,
            "siteReferrer" : sref,
            "isIFrame" : isIFrame,
            "userAgent" : navigator.userAgent
        }
      }
    });
  } 
  catch(error) {
    return handleError(requestId, error, url);
  }
}
