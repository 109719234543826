import axios from 'axios';
import uuid from 'uuid';
import { apiDomain, handleError } from './apiUtils';

export const validateTokens = async ({AccountId, SessionToken, AccessTokenV2}) => {
  const requestId = uuid();
  let url;

  try {
    url = `${apiDomain()}/authentication/portal/sessions`;

    return await axios({
      method: "post",
      url: url, 
      headers: { 
        "Content-Type" : "application/json",
        "method-client" : "portal-signin",
        "method-request-id": requestId
      },
      data: {
        AccountId,
        SessionToken,
        AccessTokenV2,
        Client: "portal-signin"
      }
    });
  } 
  catch(error) {
    return handleError(requestId, error, url);
  }
}