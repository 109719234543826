import 'react-app-polyfill/ie11';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/string/includes';
import 'core-js/fn/object/entries';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import PortalSignIn from './PortalSignIn';
import './index.css';
import { logError } from './api/errorLogger';
// import Sandbox from './sandbox';
// import * as serviceWorker from './serviceWorker';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers, /* preloadedState, */ 
  composeEnhancers(applyMiddleware(reduxThunk))
);

class App extends React.Component {
  componentDidCatch(error, info) {
    logError({
      account: window.AccountId,
      error: `${error.message} || ${info.componentStack}`
    });
  }

  render() {
    return (
    <Provider store={store}>
      <PortalSignIn /> 
    </Provider>
    );
  }

};

ReactDOM.render( <App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
