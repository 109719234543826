export default {
  black: '#000000',
  blackHover: '#1A1A1A',
  white: '#FFFFFF',
  whiteHover: '#EEF5F7',
   
  'method-darkest': '#000A22',
  'method-darker': '#0F1B31',
  'method-dark': '#022266',
  method: '#022266',
  'method-light': '#344A62',
  'method-lighter': '#4D637B',
  'method-lightest': '#677D95',
  'method-clear': 'rgba(26, 48, 72, 0.3)',

  'blue-darkest': '#022266',
  'blue-darker': '#022266',
  'blue-dark': '#064499',
  blue: '#0D71C8',
  'blue-light': '#B3E5FF',
  'blue-lighter': '#E5F7FF',
  'blue-lightest': '#E5F7FF',
  'blue-clear': '#EEF5F7',
  'blue-alice': '#ECF4FB',

  'green-darkest': '#053023',
  'green-darker': '#053023',
  'green-dark': '#115B40',
  green: '#007B4A',
  'green-light': '#A9D9BF',
  'green-lighter': '#E7F5F0',
  'green-lightest': '#E7F5F0',
  'green-clear': '#E7F5F0',

  'fuchsia-darkest': '#470219',
  'fuchsia-darker': '#470219',
  'fuchsia-dark': '#940D33',
  fuchsia: '#D81F47',
  'fuchsia-light': '#FFABB5',
  'fuchsia-lighter': '#FFF0F0',
  'fuchsia-lightest': '#FFF0F0',
  'fuchsia-clear': '#FFF0F0',

  'grey-darkest': '#0F1B31',
  'grey-darker': '#0F1B31',
  'grey-dark': '#2A394A',
  grey: '#545F67',
  'grey-light': '#BACAD0',
  'grey-lighter': '#EEF5F7',
  'grey-lightest': '#EEF5F7', // #EDF8FF
  'grey-clear': '#BACAD0',
  'grey-calendar-v2': '#E5E7Eb',
  'grey-calendar-v2-menu-icon': '#6B7280',
  'grey-calendar-v2-menu-border': '#EFEFEF',
  concrete: '#F6FAFB',

  'yellow-darkest': '#634002',
  'yellow-darker': '#634002',
  'yellow-dark': '#B07F0D',
  yellow: '#FDCB2E',
  'yellow-light': '#FFEA80',
  'yellow-lighter': '#FFFAD1',
  'yellow-lightest': '#FFFAD1',
  'yellow-clear': '#FFFAD1',

  'red-darkest': '#540009',
  'red-darker': '#540009',
  'red-dark': '#A10007',
  red: '#DA211E',
  'red-light': '#FFACA1',
  'red-lighter': '#FFF3F0',
  'red-lightest': '#FFF3F0',
  'red-clear': '#FFF3F0',

  'purple-darkest': '#0A1071',
  'purple-darker': '#0A1071',
  'purple-dark': '#1C26AA',
  purple: '#3B4AED',
  'purple-light': '#91A2FF',
  'purple-lighter': '#E3E9FF',
  'purple-lightest': '#E3E9FF',
  'purple-clear': '#E3E9FF',

  'orange-darkest': '#471602',
  'orange-darker': '#471602',
  'orange-dark': '#94390D',
  orange: '#E05D2A',
  'orange-light': '#FFCDAB',
  'orange-lighter': '#FFFAF0',
  'orange-lightest': '#FFFAF0',
  'orange-clear': '#FFFAF0',

  'teal-darker': '#03384F',
  'teal-dark': '#0F7B9C',
  teal: '#2EC9E8',
  'teal-light': '#84F3FF',
  'teal-lighter': '#D6FEFF',

  'light-gray': '#D9D9D9',
  transparent: '#00000000',
  
  // new palette
  newPalette: {
    method: {
      lighter: '#E5F7FF',
      light: '#B3E5FF',
      base: '#0D71C8',
      dark: '#064499',
      darker: '#022266',
      text: {
        lighter: '#000',
        light: '#000',
        base: '#FFF',
        dark: '#FFF',
        darker: '#FFF'
      }
    },
    steel: {
      lighter: '#EEF5F7',
      light: '#BACAD0',
      base: '#545F67',
      dark: '#2A394A',
      darker: '#0F1B31',
      text: {
        lighter: '#000',
        light: '#000',
        base: '#FFF',
        dark: '#FFF',
        darker: '#FFF'
      }
    },
    cyan: {
      lighter: '#D6FEFF',
      light: '#84F3FF',
      base: '#2EC9E8',
      dark: '#0F7B9C',
      darker: '#03384F',
      text: {
        lighter: '#000',
        light: '#000',
        base: '#000',
        dark: '#FFF',
        darker: '#FFF'
      }
    },
    ice: {
      lighter: '#E3E9FF',
      light: '#91A2FF',
      base: '#3B4AED',
      dark: '#1C26AA',
      darker: '#0A1071',
      text: {
        lighter: '#000',
        light: '#000',
        base: '#FFF',
        dark: '#FFF',
        darker: '#FFF'
      }
    },
    red: {
      lighter: '#FFF3F0',
      light: '#FFACA1',
      base: '#DA211E',
      dark: '#A10007',
      darker: '#540009',
      text: {
        lighter: '#000',
        light: '#000',
        base: '#FFF',
        dark: '#FFF',
        darker: '#FFF'
      }
    },
    yellow: {
      lighter: '#FFFAD1',
      light: '#FFEA80',
      base: '#FDCB2E',
      dark: '#B07F0D',
      darker: '#634002',
      text: {
        lighter: '#000',
        light: '#000',
        base: '#000',
        dark: '#FFF',
        darker: '#FFF'
      }
    },
    green: {
      lighter: '#E7F5F0',
      light: '#A9D9BF',
      base: '#007B4A',
      dark: '#115B40',
      darker: '#053023',
      text: {
        lighter: '#000',
        light: '#000',
        base: '#000',
        dark: '#FFF',
        darker: '#FFF'
      }
    },
    fuschia: {
      lighter: '#FFF0F0',
      light: '#FFABB5',
      base: '#D81F47',
      dark: '#940D33',
      darker: '#470219',
      text: {
        lighter: '#000',
        light: '#000',
        base: '#FFF',
        dark: '#FFF',
        darker: '#FFF'
      }
    }
  },

  legacy: {
    greyDark: '#2A394A',
    greyDarkHover: '#0F1B31',
    grey: '#BACAD0',
    greyHover: '#2A394A',
    greyLight: '#EEF5F7',
    pink: '#D81F47',
    pinkHover: '#940D33',
    'pink-light': '#FFF0F0',
    blue: '#0D71C8',
    blueHover: '#064499',
    'blue-light': '#E5F7FF',
    aqua: '#2EC9E8',
    aquaHover: '#0F7B9C',
    'aqua-light': '#D6FEFF',
    purple: '#3B4AED',
    purpleHover: '#1C26AA',
    'purple-light': '#E3E9FF',

    green: '#007B4A',
    'green-light': '#E7F5F0',
    greenAlt: '#007B4A',
    greenAltHover: '#115B40',
    red: '#DA211E',
    redHover: '#A10007',
    'red-light': '#FFF3F0',

    yellow: '#FDCB2E',
    yellowAlt: '#FDCB2E',
    yellowAltHover: '#B07F0D',
    'yellow-light': '#FFFAD1',

    orange: '#E05D2A',
    orangeAlt: '#E05D2A',
    orangeAltHover: '#94390D',
    'orange-light': '#FFFAF0',

    navHover: '#0D71C8',
    lightPurple: '#E5F7FF',
    mBlue: '#022266',
    silver: '#BACAD0',
    mDarkGrey: '#545F67',
    paleGrey: '#EEF5F7',
    appHeader: '#EEF5F7',
    appHeaderHover: '#BACAD0'
  }
 };