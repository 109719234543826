import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from "formik";
import { enterEmail } from "../withFormik";
import * as s from './EnterEmailForm.styles';
import { colors } from '../../base';

import FormInput from '../../components/FormInput';
import FormButton from '../../components/FormButton';
import FormMessage from '../../components/FormMessage';
import FormError from '../../components/FormError';
import CompanyLogo from '../../components/CompanyLogo';
import PoweredBy from '../../components/FormPoweredBy';
import { handleSsoCodeError } from '../../helpers/errors';
import { apiDomain } from '../../api/apiUtils';
import FormNav from '../../components/FormNav/FormNav';

const EMAIL = 'email';

function EnterEmailForm({
  companyLogo,
  companyName,
  values,
  errors,
  setErrors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleSso,
  ssoCode,
  handleBackPress,
  handleNextPress,
  isBackPressable,
  isNextPressable,
  resetForm,
}) {

  useEffect(() => {
    const handleSSOAsync = async () => {
      if (ssoCode) {
        setSubmitting(true);

        const handleSubmitError = (plainError) => {
          handleSsoCodeError(plainError, setErrors);
        }

        const reqStatus = await handleSso(handleSubmitError); // callback for api-call action

        if (reqStatus !== 200) {
          setSubmitting(false); // must keep the button disabled while request is being processed
        }
      }
    }

    handleSSOAsync().catch();

  }, [ssoCode])

  return (
    <>
      <FormNav
        handleBackPress={() => {
          handleBackPress();
          resetForm();
        }}
        handleNextPress={handleNextPress}
        isBackPressable={() => isBackPressable() || errors.title}
        isNextPressable={isNextPressable}
      />
      <Form style={{ width: '100%', maxWidth: '100%' }}>
        <CompanyLogo logoSource={companyLogo} name={companyName} />
        <s.MobileFormat>
          <FormMessage className="signInText" fontWeight={600} color={colors['grey-dark']} style={{ marginBottom: (!errors.title) ? '8px' : '24px' }}>
            Sign in to your portal
          </FormMessage>
          {errors.title && (
            <FormError errorMessage={errors.title} errorId={errors.message} />
          )}
          {!errors.title && (
            <s.FormButtons>
              <s.ssoLink href={`${apiDomain()}/authentication/portal/openid?provider=google&deviceApp=Web&returnUrl=${encodeURIComponent(window.location.href)}`} rel='external'>
                <FormButton type='button' buttonStyle='secondary' disabled={false} buttonText='Sign in with Google' icon='google-2' />
              </s.ssoLink>

              <s.ssoLink href={`${apiDomain()}/authentication/portal/openid?provider=microsoft&deviceApp=Web&returnUrl=${encodeURIComponent(window.location.href)}`} rel='external'>
                <FormButton type='button' buttonStyle='secondary' disabled={false} buttonText='Sign in with Microsoft' icon='microsoft' />
              </s.ssoLink>

              { /* <s.ssoLink href={`${apiDomain()}/authentication/portal/openid?provider=facebook&deviceApp=Web&returnUrl=${encodeURIComponent(window.location.href)}`} rel='external'>
                <FormButton type='button' buttonStyle='secondary' disabled={false} buttonText='Sign in with Facebook' icon='facebook' />
          </s.ssoLink> */}

              <s.LineBreak><span>Or</span></s.LineBreak>
              <FormMessage className='formMessageText' fontWeight={400} color={colors['grey']} style={{ marginBottom: '16px' }}>
                Sign in easily with a one-time code
              </FormMessage>
            </s.FormButtons>
          )}

          {errors.email ? (
            <>
              <s.ErrorEmailInput>
                <FormInput
                  autoComplete='off'
                  id={`portal-signin-${EMAIL}-input`}
                  name={EMAIL}
                  value={values[EMAIL]}
                  handleChange={handleChange}
                  inputLabel="Email address"
                  hasError={Boolean(errors.title)}
                />
              </s.ErrorEmailInput>

              <s.InlineError>
                <span id="InlineErrorIcon" className="mi-v4-validation-icon">
                  <svg width="14px" height="14px" viewBox="0 0 1024 1024" preserveAspectRatio="none" fill="red" xmlns="http://www.w3.org/2000/svg">
                    <path d="M499.029 42.667l-8.747 0.085c-131.456 2.261-255.104 55.381-348.288 149.547-93.781 94.805-144.171 219.008-141.909 349.611 4.693 270.421 216.533 482.219 482.347 482.219l8.619-0.043c274.987-4.821 494.933-228.779 490.197-499.243-4.693-270.379-216.491-482.176-482.219-482.176zM490.283 981.419l-7.893 0.043c-242.261 0-435.371-193.408-439.68-440.32-2.091-119.040 43.947-232.277 129.579-318.848 85.291-86.229 198.443-134.827 318.72-136.917l8.021-0.043c242.219 0 435.243 193.365 439.552 440.235 4.309 246.955-196.821 451.456-448.299 455.851zM490.667 618.667c11.776 0 21.333-9.557 21.333-21.333v-298.667c0-11.776-9.557-21.333-21.333-21.333s-21.333 9.557-21.333 21.333v298.667c0 11.776 9.557 21.333 21.333 21.333z M533.333 746.667c0 23.564-19.103 42.667-42.667 42.667s-42.667-19.103-42.667-42.667c0-23.564 19.103-42.667 42.667-42.667s42.667 19.103 42.667 42.667z" stroke="inherit" stroke-width=""></path>
                  </svg>
                </span>
                <div id="InlineErrorText">
                  A valid email is required.
                </div>
              </s.InlineError>
            </>
          ) : (
            <s.EmailInput>
              <FormInput
                className="portal-branding-signin-email-input"
                autoComplete='off'
                id={`portal-signin-${EMAIL}-input`}
                name={EMAIL}
                value={values[EMAIL]}
                handleChange={handleChange}
                inputLabel="Email address"
                hasError={Boolean(errors.title)}
              />
            </s.EmailInput>
          )}

          <FormButton
            type='submit'
            disabled={isSubmitting || !values[EMAIL]}
            buttonText='Send code'
          />
          <PoweredBy />
        </s.MobileFormat>
      </Form>
    </>
  )
}

EnterEmailForm.propTypes = {
  errors: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  values: PropTypes.instanceOf(Object),
  companyLogo: PropTypes.string,
  companyName: PropTypes.string,
  handleSso: PropTypes.func,
  ssoCode: PropTypes.string,
  setErrors: PropTypes.func,
  setSubmitting: PropTypes.func,
  handleBackPress: PropTypes.func,
  handleNextPress: PropTypes.func,
  isBackPressable: PropTypes.func,
  isNextPressable: PropTypes.func,
  resetForm: PropTypes.func,
};

export default enterEmail(EnterEmailForm);