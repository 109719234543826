import styled from '@emotion/styled';
import { mq } from '../../base';

export const CompanyLogo = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    max-height: 148px;
    margin: 0px;
    margin-bottom: 20px;
    padding: 0 16px;
    
    & img {
        width: auto;
        max-height: 96px;
        max-width: 320px;
        border-radius: 4px;
    }

    ${mq.mobileL} {
        width: 100%;
        margin-bottom: 40px;
        & img {
            max-height: 112px;
        }
    }

    ${mq.mobileL} {
        & img {
            max-height: 148px;
        }
    }
`;