import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import * as s from './FormError.styles';

function FormError({ errorMessage, errorId }) {
  return <s.FormErrorBox>
    <s.ErrorIconBackground>
      <s.ErrorIcon viewBox="0 0 16 16">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.58332 10.6264C7.70666 10.544 7.85167 10.5 8 10.5C8.19891 10.5 8.38968 10.579 8.53033 10.7197C8.67098 10.8603 8.75 11.0511 8.75 11.25C8.75 11.3983 8.70601 11.5433 8.6236 11.6667C8.54119 11.79 8.42405 11.8861 8.28701 11.9429C8.14997 11.9997 7.99917 12.0145 7.85368 11.9856C7.7082 11.9567 7.57456 11.8852 7.46967 11.7803C7.36478 11.6754 7.29335 11.5418 7.26441 11.3963C7.23547 11.2508 7.25032 11.1 7.30709 10.963C7.36386 10.8259 7.45999 10.7088 7.58332 10.6264ZM8.09567 11.481C8.11073 11.4747 8.12521 11.467 8.13889 11.4579C8.16632 11.4395 8.18972 11.4161 8.20787 11.3889C8.21692 11.3753 8.22467 11.3609 8.23097 11.3457C8.24359 11.3152 8.25 11.2827 8.25 11.25C8.25 11.2337 8.24841 11.2174 8.2452 11.2012C8.23555 11.1527 8.21174 11.1082 8.17678 11.0732C8.14181 11.0383 8.09726 11.0144 8.04877 11.0048C8.03263 11.0016 8.0163 11 8 11C7.96733 11 7.93481 11.0064 7.90433 11.019C7.88913 11.0253 7.87466 11.0331 7.86111 11.0421C7.83394 11.0603 7.81046 11.0837 7.79213 11.1111C7.78299 11.1248 7.77527 11.1393 7.76903 11.1543C7.75653 11.1845 7.75 11.217 7.75 11.25C7.75 11.2665 7.75163 11.2828 7.7548 11.2988C7.76438 11.3469 7.788 11.3916 7.82322 11.4268C7.85844 11.462 7.90309 11.4856 7.95123 11.4952C7.96717 11.4984 7.9835 11.5 8 11.5C8.03299 11.5 8.06548 11.4935 8.09567 11.481Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 3C8.27614 3 8.5 3.22386 8.5 3.5V9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9V3.5C7.5 3.22386 7.72386 3 8 3Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="white"/>
      </s.ErrorIcon>
    </s.ErrorIconBackground>
    <ErrorMessage errorText={errorMessage} errorId={errorId} />
  </s.FormErrorBox>
}

FormError.propTypes = {
  errorMessage: PropTypes.string,
  errorId: PropTypes.string
};

FormError.defaultProps = {
  errorMessage: ''
};

export default FormError;
