import styled from '@emotion/styled';
import { colors } from '../../base';

export const Label = styled.label`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;

  margin-bottom: 10px;

  cursor: pointer;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
`;

export const Caption = styled.span`
  padding-left: 8px;
  vertical-align: middle;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ checked }) => checked ? colors['blue'] : colors['white']};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid ${colors['grey-light']};

  ${Icon} {
    visibility: ${({ checked }) => checked ? 'visible' : 'hidden'};
  }
`;