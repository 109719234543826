import styled from '@emotion/styled';
import { colors, mq } from '../../base';

export const Title = styled.h1`
    margin: 0;
    padding-bottom: 16px;

    text-align: center;
    font-weight: 600;
    font-size: 24px !important;
    line-height: 28px;
    color: ${colors['black']};
    user-select: none;
    ${(props) => props.hideTitle && 'display: none'};

    ${mq.mobileL} {
        font-size: 28px !important;
        line-height: 36px;
    }
`;