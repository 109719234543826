import styled from "@emotion/styled";
import { colors, mq } from "../../base";

export const FormErrorBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 384px;
  margin: 16px auto;

  background-color: ${colors['red-lighter']};
  border-radius: 3px;
  padding: 8px 12px;
  display: flex;

  color: ${colors['fuchsia-darker']};
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;

  & > p {
    margin: 0;
  }

  ${mq.mobileL} {
    padding: 16px;
    padding-right: 24px;
  }
`;

export const ErrorIconBackground = styled.div`
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 32px;
  background-color: ${colors['red']};
  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const ErrorIcon = styled.svg`
  height: 16px;
  width: 16px;
`;
