import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as s from './FormCodeInput.styles';

function FormCodeInput ({ 
    value, 
    handleChange, 
    fields = 6,
    IsOnError,
}) {
    const [valueArray, setValueArray] = useState(Array(fields).fill(''));
    const canHandleChange = useRef(true);

    function focusNextOrPreviousItem(evt, isNext){
        const { form } = evt.target;
        const index = [...form].indexOf(evt.target);

        if (isNext)
            form.elements[index + 1].focus();
        else
            form.elements[index - 1].focus();

        evt.preventDefault();
    }

    function setValueArrayDigit(index, individualValue) {
        const modifiedValueArray = [...valueArray];
        modifiedValueArray[index] = individualValue;

        setValueArray(modifiedValueArray);
        handleChange(modifiedValueArray.map((x) => (x === '') ? ' ' : x).join(''));
    }

    function handleLocalChange(evt, index) {
        if(!canHandleChange.current) {
            return;
        }            

        setValueArrayDigit(index, evt.currentTarget.value);
        focusNextOrPreviousItem(evt, true);
    }
    
    function handleFocus(evt) {
        evt.target.select();
    }

    function isNumber(string){        
        if (typeof string !== 'string') {
            return false;
        }

        if (string.trim() === '') {
            return false;
        }

        return !Number.isNaN(string);
    }
    
    function handleKeyDown(evt, index, item) {
        if (evt.key === 'ArrowLeft' && index > 0) {
            focusNextOrPreviousItem(evt, false);
        }
        else if (evt.key === 'ArrowRight' && index < (fields - 1)) {
            focusNextOrPreviousItem(evt, true);
        }
        // else if (evt.key === 'Backspace') {
            
        // }
        // else if (evt.key === 'Delete') {

        // }
        // If it's a number and this number is the same it was (doesn't trigger the onChange) then just move to the next
        else if (isNumber(evt.key) !== '' && (isNumber(evt.key) && evt.key === item)) {
            focusNextOrPreviousItem(evt, true);
        }
    }

    function handlePasteAnywhere(evt) {
        if (isNumber(evt.clipboardData.getData('text')) && evt.clipboardData.getData('text').length === fields){
            setValueArray(evt.clipboardData.getData('text').split(''));
            handleChange(evt.clipboardData.getData('text'));
        }
    };

    function handleLocalPasting() {
        canHandleChange.current = false;
        setTimeout(() => { canHandleChange.current = true; }, 100);
    };

    function manageExternalValue() {
        const valueFilteredByInput = String(value).slice(0, fields);
        const arrayValueFilteredByInput = valueFilteredByInput.split('');

        if (arrayValueFilteredByInput.length < fields) {
            const count = fields - arrayValueFilteredByInput.length;
            for (let i = 0; i < count; i++) {
                arrayValueFilteredByInput.push('');
            }
        }

        setValueArray(arrayValueFilteredByInput.map((x) => (x === ' ') ? '' : x));
        handleChange(arrayValueFilteredByInput.join(''));
    };

    useEffect(() => {
        window.addEventListener('paste', handlePasteAnywhere);
        if (value) {
            manageExternalValue();
        }

        return () => {
            window.removeEventListener('paste', handlePasteAnywhere);
        };
    }, []);

    useEffect(() => {
        manageExternalValue();
    }, [value]);

    return (
        <s.CodeInputContainer>
            {valueArray.map((item, index) => 
                <s.CodeInputCellContainer 
                    key={`CodeInputCellContainer-${index}`} // eslint-disable-line
                >
                    <s.CodeInputCell
                        className="portal-branding-code-input"
                        key={`CodeInputCell-${index}`} // eslint-disable-line
                        maxLength={1}
                        value={item}
                        onChange={(evt) => handleLocalChange(evt, index)}
                        onFocus={(evt) => handleFocus(evt)} 
                        onKeyDown={(evt) => handleKeyDown(evt, index, item)}
                        onPaste={(evt) => handleLocalPasting(evt)}
                        IsOnError={IsOnError}
                    />
                    { index === 2 && 
                        <s.Separator 
                            key={`CodeInputCellSeparator-${index}`} // eslint-disable-line
                        /> 
                    }
                </s.CodeInputCellContainer>
            )}
        </s.CodeInputContainer>          
    );
};

FormCodeInput.propTypes = {
    value: PropTypes.string,
    handleChange: PropTypes.func,
    fields: PropTypes.number,
    IsOnError: PropTypes.bool,
}

export default FormCodeInput;