import * as env from '../constants/envTypes';
import Url from 'url-parse';


export const mainDomain = () => window._MAIN_DOMAIN;

export const portalDomain = () => window._PORTAL_DOMAIN;


export class ExtractUrlData {
  pName = "";
  pQueryCode = "";
  pSsoCode = "";
  hasInvalidQuery = false;

  constructor(href) {
    if (href && (typeof href === 'string')) {
      const {pathname, query} = new Url(href);
      
      this.extractNameFromUrl(pathname);

      (typeof query === 'string') 
        && this.extractCodesFromUrl(query);
    }
  }

  extractNameFromUrl(path) {
    if (typeof path === 'string' && path.length > 1) {
      this.pName = path.substring(1);
    }
  }

  extractCodesFromUrl(query) {
    const { values } = extractVariablesFromUrl(query);

    if (query.length > 1 && ('code' in values)) {
      this.pQueryCode = values['code'];
    } 
    if (query.length > 1 && ('ssoCode' in values)) {
      this.pSsoCode = values['ssoCode'];
    } 

    if (query.length > 1 && this.pQueryCode === "" && this.pSsoCode === "") {
      this.hasInvalidQuery = true;
    }
  }
}

export const extractVariablesFromUrl = (href) => {  
  const values = {};

  if (href && typeof href === 'string' && href.length > 1) {
    const questionMarkIndex = href.indexOf('?');
    let queryString = '';

    if (questionMarkIndex !== -1)
      queryString = href.slice(questionMarkIndex + 1, href.length);
    
    if (queryString.length > 1) {
      const pairs = queryString.split('&');
      pairs.forEach((item) => {
        const keyValue = item.split('=');

        if (keyValue.length === 2)
          values[`${keyValue[0]}`] = keyValue[1];   // eslint-disable-line
      })
    }    
  }

  return {
    values
  };
};

export const removeQueryFromUrl = () => {
  // main url is the portal screen url without any codes
  const { href } = window.location;
  const qIndex = href.indexOf('?');

  if (qIndex > -1) {
    const originalUrl = href.slice(0, qIndex);

    if (window.history.pushState) {
      window.history.pushState(null, null, originalUrl);
    } else {
      window.location = originalUrl;
    }
  }
};

// update browser window/tab title based on given portal data
export const displayTitle = (title) => {
  document.title = title ? `| ${title}` : '|';
}

// url encoder for errorLogger body
export const toUrlEncoded = obj => Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&');

