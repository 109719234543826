import React from 'react';
import PropTypes from 'prop-types';
import FormMessage from '../../components/FormMessage';
import FormLink from '../../components/FormLink';

const OfflineForm = ({companyEmail, companyName, handleContactClick }) => {
    var offlinemessage = "Sorry, the Customer Portal you are trying to access is inactive. ";
    
    if (Boolean(companyEmail)) {
        offlinemessage += "Please use the contact email below for assistance.";
    }

    return (
        <>
            <FormMessage style={{ marginBottom: '24px'}}>{offlinemessage}</FormMessage>
            {companyEmail && ( 
                <FormLink
                    email={companyEmail}
                    name={companyName || undefined}
                    onClick={handleContactClick}
                />
            )}     
        </>
    );
};

OfflineForm.propTypes = {
    companyEmail: PropTypes.string,
    companyName: PropTypes.string,
    handleContactClick: PropTypes.func
}

export default OfflineForm;