import axios from 'axios';
import uuid from 'uuid';
import { apiDomain,  handleError } from './apiUtils';


export const fetchData = async (PortalName) => {
  const requestId = uuid();
  let url;

  try {
    url = `${apiDomain()}/account/accounts/portaldata/${PortalName}`;

    return await axios({
      method: "get",
      url: url,
      headers: { 
        "method-client" : "portal-signin",
        "method-request-id": requestId
      }
    });
  } 
  catch (error) {
    return handleError(requestId, error, url);
  }
};