import React from 'react';
import PropTypes from 'prop-types';
import FormMessage from '../../components/FormMessage';
import FormButton from '../../components/FormButton';
import FormIcon from '../../components/FormIcon';
import FormNav from '../../components/FormNav/FormNav';
import * as s from './SniperLinkForm.styles';
import { colors } from '../../base';

function SniperLinkForm({ 
  provider, 
  email, 
  handleSniperLink,
  handleBackPress,
  handleNextPress,
  isBackPressable,
  isNextPressable,
}) {
  return (
    <> 
      <FormNav
        handleBackPress={handleBackPress}
        handleNextPress={handleNextPress}
        isBackPressable={isBackPressable}
        isNextPressable={isNextPressable}
      />
      <s.Container>
        <FormIcon icon='email' backgroundSize={71} width={36} height={25} isSelected={false} style={{ marginLeft: 'auto', marginRight: 'auto'}}/>
        <s.MobileFormat>
          <FormMessage className='codeSentText' fontWeight={600} style={{ marginBottom: '8px', color: colors['grey-darker'] }}>
            Code sent! Check your inbox.
          </FormMessage>
          <FormMessage className='formMessageText' fontWeight={400} color={colors['grey']} style={{ marginBottom: '28px' }}>
            Sign in instantly by copying the secure code sent to <span style={{ fontWeight: 600, fontSize: 14, color: colors['grey-dark'] }}>{email}</span>.
          </FormMessage>
          <FormMessage className='formShortcutText' fontWeight={400} color={colors['grey']} style={{ marginBottom: '8px' }}>
            Here’s a shortcut to make it even easier for you:
          </FormMessage>
        </s.MobileFormat>
        <FormButton type='submit' buttonStyle='secondary' disabled={false} buttonText='Check your inbox' icon={provider} onClick={() => handleSniperLink()}/>
      </s.Container>
    </>
  )
}

export default SniperLinkForm;

SniperLinkForm.propTypes = {
    provider: PropTypes.string, 
    email: PropTypes.string, 
    handleSniperLink: PropTypes.func,
    handleBackPress: PropTypes.func,
    handleNextPress: PropTypes.func,
    isBackPressable: PropTypes.func,
    isNextPressable: PropTypes.func,
};