import React from 'react';
import PropTypes from 'prop-types';
import * as s from './CompanyLogo.styles';

function CompanyLogo ({ logoSource, name }) {
    if (logoSource) {
        return (
            <s.CompanyLogo >
                <img src={logoSource} alt={name}/>
            </s.CompanyLogo>
        );
    }
    return null;
};

CompanyLogo.propTypes = {
    logoSource: PropTypes.string,
    name: PropTypes.string
}

export default CompanyLogo;