import React from 'react';
import PropTypes from 'prop-types';
import * as s from './FormIcon.styles';

function FormIcon ({ icon, backgroundSize, width, height, isSelected, style }) {
    return (
        <s.IconBackground size={backgroundSize} isSelected={isSelected} style={style} className='portal-branding-icon'>                
            <s.BoxIcon style={{ width: width, height: height }} isSelected={isSelected} viewBox={`0 0 ${width} ${height}`}>
                {icon && icon.toLowerCase() === 'email' && (
                    <path d="M33 0H3C2.33696 0 1.70107 0.263392 1.23223 0.732233C0.763392 1.20107 0.5 1.83696 0.5 2.5V22.5C0.5 23.163 0.763392 23.7989 1.23223 24.2678C1.70107 24.7366 2.33696 25 3 25H33C33.663 25 34.2989 24.7366 34.7678 24.2678C35.2366 23.7989 35.5 23.163 35.5 22.5V2.5C35.5 1.83696 35.2366 1.20107 34.7678 0.732233C34.2989 0.263392 33.663 0 33 0ZM30.25 2.5L18 10.975L5.75 2.5H30.25ZM3 22.5V3.6375L17.2875 13.525C17.4967 13.6702 17.7453 13.7479 18 13.7479C18.2547 13.7479 18.5033 13.6702 18.7125 13.525L33 3.6375V22.5H3Z" fill="#0D71C8"/>
                )}
                {icon && icon.toLowerCase() === 'lock' && (
                    <>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.75195 18C8.0616 18 7.50195 18.5596 7.50195 19.25V36.75C7.50195 37.4404 8.0616 38 8.75195 38H31.252C31.9423 38 32.502 37.4404 32.502 36.75V19.25C32.502 18.5596 31.9423 18 31.252 18H8.75195ZM5.00195 19.25C5.00195 17.1789 6.68089 15.5 8.75195 15.5H31.252C33.323 15.5 35.002 17.1789 35.002 19.25V36.75C35.002 38.8211 33.323 40.5 31.252 40.5H8.75195C6.68089 40.5 5.00195 38.8211 5.00195 36.75V19.25Z" fill="#0D71C8"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M20.0029 3C18.0138 3 16.1062 3.79018 14.6996 5.1967C13.2931 6.60322 12.5029 8.51088 12.5029 10.5V16.75C12.5029 17.4404 11.9433 18 11.2529 18C10.5626 18 10.0029 17.4404 10.0029 16.75V10.5C10.0029 7.84784 11.0565 5.3043 12.9319 3.42893C14.8072 1.55357 17.3508 0.5 20.0029 0.5C22.6551 0.5 25.1986 1.55357 27.074 3.42893C28.9494 5.3043 30.0029 7.84784 30.0029 10.5V16.75C30.0029 17.4404 29.4433 18 28.7529 18C28.0626 18 27.5029 17.4404 27.5029 16.75V10.5C27.5029 8.51088 26.7128 6.60322 25.3062 5.1967C23.8997 3.79018 21.9921 3 20.0029 3Z" fill="#0D71C8"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.3367 23.316C13.6451 23.11 14.0076 23 14.3784 23C14.8757 23 15.3526 23.1975 15.7042 23.5492C16.0559 23.9008 16.2534 24.3777 16.2534 24.875C16.2534 25.2458 16.1435 25.6084 15.9374 25.9167C15.7314 26.225 15.4386 26.4654 15.0959 26.6073C14.7533 26.7492 14.3763 26.7863 14.0126 26.714C13.6489 26.6416 13.3148 26.4631 13.0526 26.2008C12.7904 25.9386 12.6118 25.6045 12.5394 25.2408C12.4671 24.8771 12.5042 24.5001 12.6461 24.1575C12.7881 23.8149 13.0284 23.522 13.3367 23.316ZM14.6176 25.4524C14.6552 25.4368 14.6914 25.4175 14.7256 25.3947C14.7942 25.3488 14.8527 25.2901 14.8981 25.2222C14.9207 25.1884 14.9401 25.1522 14.9558 25.1142C14.9874 25.038 15.0034 24.9567 15.0034 24.875C15.0034 24.8343 14.9994 24.7934 14.9914 24.7531C14.9673 24.6318 14.9078 24.5205 14.8204 24.4331C14.7329 24.3456 14.6216 24.2861 14.5003 24.262C14.46 24.254 14.4192 24.25 14.3784 24.25C14.2967 24.25 14.2154 24.266 14.1392 24.2976C14.1012 24.3133 14.0651 24.3327 14.0312 24.3553C13.9633 24.4007 13.9046 24.4592 13.8587 24.5278C13.8359 24.562 13.8166 24.5982 13.801 24.6358C13.7697 24.7113 13.7534 24.7925 13.7534 24.875C13.7534 24.9162 13.7575 24.9571 13.7654 24.9969C13.7894 25.1173 13.8484 25.2289 13.9365 25.3169C14.0245 25.405 14.1361 25.4641 14.2565 25.488C14.2964 25.4959 14.3372 25.5 14.3784 25.5C14.4609 25.5 14.5421 25.4837 14.6176 25.4524Z" fill="#0D71C8"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.9617 23.316C19.2701 23.11 19.6326 23 20.0034 23C20.5007 23 20.9776 23.1975 21.3292 23.5492C21.6809 23.9008 21.8784 24.3777 21.8784 24.875C21.8784 25.2458 21.7685 25.6084 21.5624 25.9167C21.3564 26.225 21.0636 26.4654 20.7209 26.6073C20.3783 26.7492 20.0013 26.7863 19.6376 26.714C19.2739 26.6416 18.9398 26.4631 18.6776 26.2008C18.4154 25.9386 18.2368 25.6045 18.1644 25.2408C18.0921 24.8771 18.1292 24.5001 18.2711 24.1575C18.4131 23.8149 18.6534 23.522 18.9617 23.316ZM20.2426 25.4524C20.2802 25.4368 20.3164 25.4175 20.3506 25.3947C20.4192 25.3488 20.4777 25.2901 20.5231 25.2222C20.5457 25.1884 20.5651 25.1522 20.5808 25.1142C20.6124 25.038 20.6284 24.9567 20.6284 24.875C20.6284 24.8343 20.6244 24.7934 20.6164 24.7531C20.5923 24.6318 20.5328 24.5205 20.4454 24.4331C20.3579 24.3456 20.2466 24.2861 20.1253 24.262C20.085 24.254 20.0442 24.25 20.0034 24.25C19.9217 24.25 19.8404 24.266 19.7642 24.2976C19.7262 24.3133 19.6901 24.3327 19.6562 24.3553C19.5883 24.4007 19.5296 24.4592 19.4837 24.5278C19.4609 24.562 19.4416 24.5982 19.426 24.6358C19.3947 24.7113 19.3784 24.7925 19.3784 24.875C19.3784 24.9162 19.3825 24.9571 19.3904 24.9969C19.4144 25.1173 19.4734 25.2289 19.5615 25.3169C19.6495 25.405 19.7611 25.4641 19.8815 25.488C19.9214 25.4959 19.9622 25.5 20.0034 25.5C20.0859 25.5 20.1671 25.4837 20.2426 25.4524Z" fill="#0D71C8"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.5867 23.316C24.8951 23.11 25.2576 23 25.6284 23C26.1257 23 26.6026 23.1975 26.9542 23.5492C27.3059 23.9008 27.5034 24.3777 27.5034 24.875C27.5034 25.2458 27.3935 25.6084 27.1874 25.9167C26.9814 26.225 26.6886 26.4654 26.3459 26.6073C26.0033 26.7492 25.6263 26.7863 25.2626 26.714C24.8989 26.6416 24.5648 26.4631 24.3026 26.2008C24.0404 25.9386 23.8618 25.6045 23.7894 25.2408C23.7171 24.8771 23.7542 24.5001 23.8961 24.1575C24.0381 23.8149 24.2784 23.522 24.5867 23.316ZM25.8676 25.4524C25.9052 25.4368 25.9414 25.4175 25.9756 25.3947C26.0442 25.3488 26.1027 25.2901 26.1481 25.2222C26.1707 25.1884 26.1901 25.1522 26.2058 25.1142C26.2374 25.038 26.2534 24.9567 26.2534 24.875C26.2534 24.8343 26.2494 24.7934 26.2414 24.7531C26.2173 24.6318 26.1578 24.5205 26.0704 24.4331C25.9829 24.3456 25.8716 24.2861 25.7503 24.262C25.71 24.254 25.6692 24.25 25.6284 24.25C25.5467 24.25 25.4654 24.266 25.3892 24.2976C25.3512 24.3133 25.3151 24.3327 25.2812 24.3553C25.2133 24.4007 25.1546 24.4592 25.1087 24.5278C25.0859 24.562 25.0666 24.5982 25.051 24.6358C25.0197 24.7113 25.0034 24.7925 25.0034 24.875C25.0034 24.9162 25.0075 24.9571 25.0154 24.9969C25.0394 25.1173 25.0984 25.2289 25.1865 25.3169C25.2745 25.405 25.3861 25.4641 25.5065 25.488C25.5464 25.4959 25.5872 25.5 25.6284 25.5C25.7109 25.5 25.7921 25.4837 25.8676 25.4524Z" fill="#0D71C8"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.3367 29.566C13.6451 29.36 14.0076 29.25 14.3784 29.25C14.8757 29.25 15.3526 29.4475 15.7042 29.7992C16.0559 30.1508 16.2534 30.6277 16.2534 31.125C16.2534 31.4958 16.1435 31.8584 15.9374 32.1667C15.7314 32.475 15.4386 32.7154 15.0959 32.8573C14.7533 32.9992 14.3763 33.0363 14.0126 32.964C13.6489 32.8916 13.3148 32.7131 13.0526 32.4508C12.7904 32.1886 12.6118 31.8545 12.5394 31.4908C12.4671 31.1271 12.5042 30.7501 12.6461 30.4075C12.7881 30.0649 13.0284 29.772 13.3367 29.566ZM14.6176 31.7024C14.6552 31.6868 14.6914 31.6675 14.7256 31.6447C14.7942 31.5988 14.8527 31.5401 14.8981 31.4722C14.9207 31.4384 14.9401 31.4022 14.9558 31.3642C14.9874 31.288 15.0034 31.2067 15.0034 31.125C15.0034 31.0843 14.9994 31.0434 14.9914 31.0031C14.9673 30.8818 14.9078 30.7705 14.8204 30.6831C14.7329 30.5956 14.6216 30.5361 14.5003 30.512C14.46 30.504 14.4192 30.5 14.3784 30.5C14.2967 30.5 14.2154 30.516 14.1392 30.5476C14.1012 30.5633 14.0651 30.5827 14.0312 30.6053C13.9633 30.6507 13.9046 30.7092 13.8587 30.7778C13.8359 30.812 13.8166 30.8482 13.801 30.8858C13.7697 30.9613 13.7534 31.0425 13.7534 31.125C13.7534 31.1662 13.7575 31.2071 13.7654 31.2469C13.7894 31.3673 13.8484 31.4789 13.9365 31.5669C14.0245 31.655 14.1361 31.7141 14.2565 31.738C14.2964 31.7459 14.3372 31.75 14.3784 31.75C14.4609 31.75 14.5421 31.7337 14.6176 31.7024Z" fill="#0D71C8"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.9617 29.566C19.2701 29.36 19.6326 29.25 20.0034 29.25C20.5007 29.25 20.9776 29.4475 21.3292 29.7992C21.6809 30.1508 21.8784 30.6277 21.8784 31.125C21.8784 31.4958 21.7685 31.8584 21.5624 32.1667C21.3564 32.475 21.0636 32.7154 20.7209 32.8573C20.3783 32.9992 20.0013 33.0363 19.6376 32.964C19.2739 32.8916 18.9398 32.7131 18.6776 32.4508C18.4154 32.1886 18.2368 31.8545 18.1644 31.4908C18.0921 31.1271 18.1292 30.7501 18.2711 30.4075C18.4131 30.0649 18.6534 29.772 18.9617 29.566ZM20.2426 31.7024C20.2802 31.6868 20.3164 31.6675 20.3506 31.6447C20.4192 31.5988 20.4777 31.5401 20.5231 31.4722C20.5457 31.4384 20.5651 31.4022 20.5808 31.3642C20.6124 31.288 20.6284 31.2067 20.6284 31.125C20.6284 31.0843 20.6244 31.0434 20.6164 31.0031C20.5923 30.8818 20.5328 30.7705 20.4454 30.6831C20.3579 30.5956 20.2466 30.5361 20.1253 30.512C20.085 30.504 20.0442 30.5 20.0034 30.5C19.9217 30.5 19.8404 30.516 19.7642 30.5476C19.7262 30.5633 19.6901 30.5827 19.6562 30.6053C19.5883 30.6507 19.5296 30.7092 19.4837 30.7778C19.4609 30.812 19.4416 30.8482 19.426 30.8858C19.3947 30.9613 19.3784 31.0425 19.3784 31.125C19.3784 31.1662 19.3825 31.2071 19.3904 31.2469C19.4144 31.3673 19.4734 31.4789 19.5615 31.5669C19.6495 31.655 19.7611 31.7141 19.8815 31.738C19.9214 31.7459 19.9622 31.75 20.0034 31.75C20.0859 31.75 20.1671 31.7337 20.2426 31.7024Z" fill="#0D71C8"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.5867 29.566C24.8951 29.36 25.2576 29.25 25.6284 29.25C26.1257 29.25 26.6026 29.4475 26.9542 29.7992C27.3059 30.1508 27.5034 30.6277 27.5034 31.125C27.5034 31.4958 27.3935 31.8584 27.1874 32.1667C26.9814 32.475 26.6886 32.7154 26.3459 32.8573C26.0033 32.9992 25.6263 33.0363 25.2626 32.964C24.8989 32.8916 24.5648 32.7131 24.3026 32.4508C24.0404 32.1886 23.8618 31.8545 23.7894 31.4908C23.7171 31.1271 23.7542 30.7501 23.8961 30.4075C24.0381 30.0649 24.2784 29.772 24.5867 29.566ZM25.8676 31.7024C25.9052 31.6868 25.9414 31.6675 25.9756 31.6447C26.0442 31.5988 26.1027 31.5401 26.1481 31.4722C26.1707 31.4384 26.1901 31.4022 26.2058 31.3642C26.2374 31.288 26.2534 31.2067 26.2534 31.125C26.2534 31.0843 26.2494 31.0434 26.2414 31.0031C26.2173 30.8818 26.1578 30.7705 26.0704 30.6831C25.9829 30.5956 25.8716 30.5361 25.7503 30.512C25.71 30.504 25.6692 30.5 25.6284 30.5C25.5467 30.5 25.4654 30.516 25.3892 30.5476C25.3512 30.5633 25.3151 30.5827 25.2812 30.6053C25.2133 30.6507 25.1546 30.7092 25.1087 30.7778C25.0859 30.812 25.0666 30.8482 25.051 30.8858C25.0197 30.9613 25.0034 31.0425 25.0034 31.125C25.0034 31.1662 25.0075 31.2071 25.0154 31.2469C25.0394 31.3673 25.0984 31.4789 25.1865 31.5669C25.2745 31.655 25.3861 31.7141 25.5065 31.738C25.5464 31.7459 25.5872 31.75 25.6284 31.75C25.7109 31.75 25.7921 31.7337 25.8676 31.7024Z" fill="#0D71C8"/>                    
                    </>
                )}
            </s.BoxIcon>  
        </s.IconBackground>          
    );
};

FormIcon.propTypes = {
    icon: PropTypes.string,
    backgroundSize: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    isSelected: PropTypes.bool,
    style: PropTypes.instanceOf(Object),
}

export default FormIcon;