import { getCookie, clearCookie } from "../helpers/cookie";
import { redirectToPortal } from "../helpers/redirects";
import { setActiveForm } from "./setActiveForm";
import { setPortalCode } from "./setPortalCode";
import { validateTokens } from "../api/validateTokensAPI";
import { COOKIE_NAME, COOKIE_NAME_V2 } from "../constants/cookieNames";
import { ENTER_CODE, ENTER_EMAIL} from '../constants/formTypes';
import { setPortalSsoCode } from "./setPortalSsoCode";
import { removeQueryFromUrl } from "../helpers/urls";
// import { mockValidateTokens } from "../api/apiMocks";

// check if user is already logged in through session cookies
export const checkCookies = (AccountId, portalName, portalQueryCode, portalSsoCode) => async (dispatch) => {
  const SessionToken = getCookie(COOKIE_NAME);
  const AccessTokenV2 = getCookie(COOKIE_NAME_V2);

  if (SessionToken && AccessTokenV2) {
    const {status, data} =  await validateTokens({AccountId, SessionToken, AccessTokenV2});
    // const { status } = await mockValidateTokens();
    if ( status >= 200 && status < 300 ) {
      redirectToPortal(portalName, data.accountLinkId);
      return;
    } 
    
    if ( status >= 400 && status < 500 ) {
      clearCookie(COOKIE_NAME);
      clearCookie(COOKIE_NAME_V2);
    } 
  }

  if (portalQueryCode) {
    // if no cookie but url includes the code as query
    dispatch(setPortalCode(portalQueryCode));
    dispatch(setActiveForm(ENTER_CODE));

  } else if (portalSsoCode) {
    // if no cookie but url includes the ssO code as query    
    dispatch(setActiveForm(ENTER_EMAIL));
    dispatch(setPortalSsoCode(portalSsoCode));
    removeQueryFromUrl();
  } else {
     // go signin page if anything goes wrong
    dispatch(setActiveForm(ENTER_EMAIL));
  }
}