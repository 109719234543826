import React from 'react';
import FormMessage from '../../components/FormMessage';
import { generalError } from '../../helpers/errors';
import ErrorMessage from '../../components/ErrorMessage';


const NoDataErrorForm = ({formErrorId}) => (
    <FormMessage type="error" >
        <ErrorMessage errorText={generalError} errorId={formErrorId} />
    </FormMessage>
);

export default NoDataErrorForm;