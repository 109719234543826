import styled from '@emotion/styled';
import { colors } from '../../base';

export const ErrorMessage = styled.span`
  opacity: 1;
`;

export const ErrorTextContainer = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
  color: ${colors['grey-darkest']};
`;

export const ErrorIdContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors['red-darkest']};
`;