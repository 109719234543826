import styled from 'styled-components';
import { colors, mq } from '../../base';

export const ssoLink = styled.a`
    text-decoration: none;
`;

export const LineBreak = styled.div`
    box-sizing: border-box;
    position: relative;
    max-width: 384px;
    width: 100%;    
    height: 1px;    
    margin: 30px auto;
    background-color: ${colors['grey-light']};

    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    span {
        width: 47px;
        text-align: center;
        position: absolute;
        background: white;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: ${colors['grey-dark']};
        user-select: none;
    }
`;

export const EmailInput = styled.div`
    #portal-signin-email-input {
        border: 1px solid ${colors['grey-light']};
        background: ${colors['white']} !important;
        border-radius: 2px !important;
        padding: 8px;
        font-size: 16px !important;
        height: 48px !important;
    }

    #portal-signin-email-input:focus {
        border: 1px solid ${colors.newPalette.method['base']};
    }

    label {
        color: ${colors['grey']};
        background-color: ${colors['white']};
        padding: 0px 4px;
        left: 4px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
    }

    .Active {
        font-size: 14pt;
        line-height: 16px;
        font-weight: 600;
    }

    &:focus-within label {
        font-size: 14pt;
        line-height: 16px;
        font-weight: 600;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${colors['white']} inset !important;
    }
`;

export const ErrorEmailInput = styled.div`
    #portal-signin-email-input {
        border: 1px solid red;
        background: ${colors['white']} !important;
        border-radius: 2px !important;
        padding: 8px;
        font-size: 16px !important;
        height: 48px !important;
    }

    #portal-signin-email-input:focus {
        border: 1px solid red;
    }

    label {
        color: red;
        background-color: ${colors['white']};
        padding: 0px 4px;
        left: 4px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
    }

    .Active {
        color: red;
        font-size: 14pt;
        line-height: 16px;
        font-weight: 600;
    }

    &:focus-within label {
        color: red;
        font-size: 14pt;
        line-height: 16px;
        font-weight: 600;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${colors['white']} inset !important;
    }
`;

export const InlineError = styled.div`
    color: red;
    display: flex;
    align-items: center;
    margin: -14px 0px 16px;

    #InlineErrorIcon {
        padding: 0px 4px;
    }

    #InlineErrorText {
        font-size: 12px;
    }
`;

export const MobileFormat = styled.div`
    .signInText {
        font-size: 24px;
        line-height: 32px;
    }

    .formMessageText {
        font-size: 16px;
        line-height: 24px;
    }

    ${mq.mobileL} {
        .signInText {
            font-size: 20px;
            line-height: 24px;
        }

        .formMessageText {
            font-size: 16px;
            line-height: 24px;
        }
    }
`;

export const FormButtons = styled.div`
    margin-top: 16px;
`;