import React from 'react';
import PropTypes from 'prop-types';
import * as s from './FormNav.styles';

function FormNav ({
    handleBackPress,
    handleNextPress,
    isBackPressable,
    isNextPressable,
}) {
    return (        
        <s.NavContainer>
          {isBackPressable() && handleBackPress &&            
            <s.Back onClick={handleBackPress}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.8718 1.12813C12.0427 1.29897 12.0427 1.57598 11.8719 1.74684L5.6193 8L11.8719 14.2532C12.0427 14.424 12.0427 14.701 11.8718 14.8719C11.701 15.0427 11.424 15.0427 11.2531 14.8718L5.00061 8.61875C5.00061 8.61874 5.0006 8.61874 5.0006 8.61873C4.91927 8.53752 4.85476 8.44108 4.81074 8.33491C4.76672 8.22874 4.74406 8.11493 4.74406 8C4.74406 7.88507 4.76672 7.77126 4.81074 7.66509C4.85476 7.55892 4.91927 7.46248 5.0006 7.38127C5.0006 7.38126 5.00061 7.38126 5.00061 7.38125L11.2531 1.12816C11.424 0.957293 11.701 0.95728 11.8718 1.12813Z" fill="#545F67"/>
              </svg>
            </s.Back>
          }
          {isNextPressable() && handleNextPress &&             
            <s.Next onClick={handleNextPress}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.0457 15.8251C4.87691 15.6522 4.88023 15.3752 5.05312 15.2064L11.3805 9.02894L5.2036 2.70101C5.03482 2.5281 5.03817 2.25111 5.21108 2.08233C5.38398 1.91356 5.66097 1.9169 5.82975 2.08981L12.0066 8.41769C12.0066 8.41769 12.0066 8.4177 12.0066 8.4177C12.0869 8.49988 12.1503 8.5971 12.193 8.70378C12.2358 8.81048 12.257 8.92455 12.2557 9.03948C12.2543 9.1544 12.2303 9.26793 12.185 9.37356C12.1397 9.47919 12.074 9.57485 11.9917 9.65507C11.9917 9.65508 11.9917 9.65508 11.9917 9.65509L5.66438 15.8325C5.49149 16.0013 5.2145 15.998 5.0457 15.8251Z" fill="#545F67"/>
              </svg>
            </s.Next>  
          }       
        </s.NavContainer>  
    );
};

FormNav.propTypes = {
    handleBackPress: PropTypes.func,
    handleNextPress: PropTypes.func,
    isBackPressable: PropTypes.func,
    isNextPressable: PropTypes.func,
}

FormNav.defaultProps = {
};

export default FormNav;